import { useCallback, useEffect, useMemo, useState } from 'react';
import { getSchemas } from 'src/api/functions/schemaApi';
import { Button, SchemaForm, Select } from 'src/components';
import InfoSection from 'src/components/InfoSection';
import { useAccessToken } from 'src/hooks/useAccessToken';
import SetupFreeFormMetadata from './SetupFreeFormData';
import useRoleManager from 'src/hooks/useRoleManager';

import styles from '../Modal.module.scss';
import { Asset } from 'src/types/TAssets';
import messages from '../messages';
import { FormattedMessage, useIntl } from 'react-intl';

type AssetMetadataSetupProps = {
  asset: Asset;
  onSubmitSchema: (data: any, schemaId: number) => void;
  schemasData:any
};

const AssetMetadataSetup = ({ asset,schemasData, onSubmitSchema }: AssetMetadataSetupProps) => {
  const [schemas, setSchemas] = useState<any>();
  const accessToken = useAccessToken();
  const [selectedSchemaId, setSelectedSchemaId] = useState('');
  const [showSchemaForm, setShowSchemaForm] = useState(false);
  const [showFreeFormData, setShowFreeFormData] = useState(false);
  const { isUser } = useRoleManager();


  const { formatMessage } = useIntl();

  const schemaSelection = useMemo(() => {
    if (!schemas) return [];

    return schemas.map((schema: any) => ({ label: schema.name.en, value: schema.id.toString() }));
  }, [schemas]);

  const selectedSchema = useMemo(
    () => schemaSelection.find((schema: any) => schema.value === selectedSchemaId),
    [selectedSchemaId],
  );

  const fetchSchemas = async () => {
    // const result = await getSchemas(accessToken);

    // console.debug(result)

    if (!schemasData) return;

    setSchemas(schemasData);
    setSelectedSchemaId(schemasData[0].id.toString());
  };

  useEffect(() => {
    fetchSchemas();
  }, []);

  const handleUseSchema = () => {
    setShowSchemaForm(true);
  };

  const handleCancel = () => {
    setShowSchemaForm(false);
  };

  const handleCancelFreeForm = () => {
    setShowFreeFormData(false);
  };

  const getSelectedSchema = useCallback(() => {
    const {
      schema: { $schema, ...data },
    } = schemas.find((schema: any) => schema.id === parseInt(selectedSchemaId));

    return data;
  }, [selectedSchemaId]);

  const handleSubmitSchema = (schemaObject: any) => {
    const schemaId = parseInt(selectedSchemaId);
    onSubmitSchema(schemaObject, schemaId);
  };

  return (
    <>
      {!showSchemaForm && !showFreeFormData && !isUser && (
        <div className={styles.metadataInitContainer}>
          <InfoSection text={formatMessage(messages.assetMetadataSetupInfo)} />
          <div className={styles.metadataInitForm}>
            <Button mode="primary" size="small" onClick={() => setShowFreeFormData(true)}>
              <FormattedMessage {...messages.addFreeFormData} />
            </Button>
            {schemas && (
              <>
                <p className={styles.separator}>OR</p>
                <div className={styles.selectionContainer}>
                  <p>
                    <FormattedMessage {...messages.selectSchema} />
                  </p>
                  <Select
                    value={selectedSchemaId}
                    onChange={(value) => setSelectedSchemaId(value)}
                    options={schemaSelection}
                  />
                  <Button mode="tertiary" size="small" onClick={handleUseSchema}>
                    Use {selectedSchema?.label} schema
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {showSchemaForm && (
        <SchemaForm schema={getSelectedSchema()} formData={{}} onCancel={handleCancel} onSubmit={handleSubmitSchema} />
      )}
      {showFreeFormData && <SetupFreeFormMetadata asset={asset} onCancel={handleCancelFreeForm} />}
    </>
  );
};

export default AssetMetadataSetup;
