import { useState, useEffect, useContext } from 'react';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { Button, Loader } from 'src/components';
import styles from './RBGviewer.module.scss';
import { getRBG } from 'src/api/functions/addonsApi';
import { AssetUpdatedDialog } from './components/AssetUpdateDialog';
import AssetModalContext from './context';
import { useQueryClient } from 'react-query';
import { updateAssetConversionMedia, updateAssetMedia, createNewAsset } from 'src/api/functions/assetsApi';
import { queryNames } from 'src/api/utils/queryNames';
import { useAlerts } from 'src/common/AlertManager';
import { useIntl } from 'react-intl';
import messages from './messages';

type RBGParams = {
  assetId: string;
  assetData: any;
  onRBGImageSaved: () => void;
  name: string;
  url: string;
};
const RBGviewer: React.FC<RBGParams> = ({ assetId, assetData, onRBGImageSaved, name, url }) => {
  const modalAPI = useContext(AssetModalContext);
  const accessToken = useAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const [base64val, setbase64val] = useState('');
  const [isError, setIsError] = useState(false);
  const [saveOptions, setSaveOptions] = useState(false);
  const queryClient = useQueryClient();
  const { showSuccess } = useAlerts();
  const { formatMessage } = useIntl();

  const showSaveOptions = () => {
    setSaveOptions(true);
  };
  const handleDownload = () => {
    if (base64val) {
      const link = document.createElement('a');
      link.href = `data:image/png;base64,${base64val}`;
      link.download = `${name.split('.')[0]}_BG_removed.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSaveOptions(false);
      modalAPI?.handleOnFinishEditing();
      onRBGImageSaved();
    }
  };
  const base64ToBlob = (base64String: string, contentType: string = 'image/png'): Blob => {
    const binaryString = window.atob(base64String);

    const byteArray = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));

    return new Blob([byteArray], { type: contentType });
  };

  const handleCreateNewAsset = async (assetName: string, includeMetadata: boolean) => {
    const pngBlob = base64ToBlob(base64val);
    await createNewAsset(
      assetId,
      { file: pngBlob, filename: `${assetName}.png` },
      accessToken,
      assetData['data'],
      includeMetadata,
    );
    showSuccess(formatMessage(messages.newAssetcreated));
    setSaveOptions(false);
    modalAPI?.handleOnFinishEditing();
    onRBGImageSaved();
  };
  const handleCreateAssetConversion = async (conversionName: string) => {
    const pngBlob = base64ToBlob(base64val);
    const response = await updateAssetConversionMedia(
      assetId,
      conversionName,
      { file: pngBlob, filename: name ? `${name.split('.')[0]}.png` : 'image.png' },
      accessToken,
    );

    queryClient.setQueryData([queryNames.asset, assetId], (data: any) => {
      data.conversions[conversionName] = response;
      return data;
    });
    showSuccess(formatMessage(messages.savedConversion, { conversionName }));

    setSaveOptions(false);
    modalAPI?.handleOnFinishEditing();
    onRBGImageSaved();
  };
  const onReplaceOriginalAsset = () => {
    const pngBlob = base64ToBlob(base64val);
    updateAssetMedia(
      assetId,
      { file: pngBlob, filename: name ? `${name.split('.')[0]}.png` : 'image.png' },
      accessToken,
    );
    showSuccess(formatMessage(messages.successfullyReplacedOriginalImage, { type: 'image' }));
    setSaveOptions(false);
    modalAPI?.handleOnFinishEditing();
    onRBGImageSaved();
  };
  const handleCancelAssetUpdate = () => {
    setSaveOptions(false);
  };

  useEffect(() => {
    const loadMediaData = async () => {
      setIsLoading(true);
      const url = await getRBG(assetId, accessToken);
      setbase64val(url);
      setIsLoading(false);
    };

    loadMediaData();
  }, [assetId, accessToken]);
  return (
    <>
      <div className={styles.main}>
        {!isError && (
          <div className={!isLoading ? styles.hidden : styles.backgroundImg}>
            <img src={url} alt="background" />
          </div>
        )}
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
            <h3>Processing Background Removal ...</h3>
          </div>
        ) : (
          <div className={styles.preview_bg}>
            {isError ? (
              <div className={styles.errorContainer}>
                <h3>Failed to load image</h3>
              </div>
            ) : (
              <>
                <div className={styles.saveoption}>
                  <button onClick={showSaveOptions}>Done</button>
                </div>
                <div className={styles.imageContainer}>
                  <img
                    style={{
                      width: assetData?.metadata?.width < 800 ? 'auto !important' : '100%',
                      height: assetData?.metadata?.height < 600 ? 'auto !important' : '100%',
                      maxWidth: assetData?.metadata?.width < 800 ? '100%' : '800px',
                      maxHeight: assetData?.metadata?.width < 600 ? '100%' : '600px',
                    }}
                    alt={isLoading ? 'Loading...' : 'RGB'}
                    src={`data:image/jpeg;base64,${base64val}`}
                    hidden={isLoading}
                    onError={(e) => {
                      setIsError(true);
                      e.currentTarget.alt = 'Failed to load image';
                      e.currentTarget.style.display = 'none';
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {saveOptions && (
        <AssetUpdatedDialog
          onCreateNewAsset={handleCreateNewAsset}
          onCreateConversion={handleCreateAssetConversion}
          onReplaceOriginal={onReplaceOriginalAsset}
          onDownloadConversion={handleDownload}
          onCancel={handleCancelAssetUpdate}
          assetType="original"
        />
      )}
    </>
  );
};

export default RBGviewer;
