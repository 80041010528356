import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryFnFetchErrorWrapper, throwFetchError } from 'src/api/utils/errorHandlers';
import { mergeQueryOptions } from 'src/api/utils/helpers';
import { queryNames } from 'src/api/utils/queryNames';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { CustomQueryOptions, FetchError, DashboardFilters } from 'src/types/TApiQueries';
import { getSearch, getSearchByHash, getAssetDetails, getAssetVisionTags, getFaceSearch, getFaceSearchByHash } from '../functions/assetsApi';
import { isValidSort } from '../utils/search';

export const statusOptions = [
  { label: 'All', value: '' },
  { label: 'Ok', value: 'OK' },
  { label: 'Archived', value: 'ARCHIVED' },
  { label: 'Error', value: 'ERROR' },
];


const sortConfig = {
  delimiter: '_',
  validFields: ['upload_time_range', 'file_name', 'content_type', 'file_size_range', 'status'],
  validDirections: ['asc', 'desc'],
};

export function useSearchQuery(
  filters: DashboardFilters,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() =>
        filters.face ? filters.hash === '' ? getFaceSearch(filters, accessToken) : getFaceSearchByHash(filters, accessToken) : filters.hash === '' ? getSearch(filters, accessToken) : getSearchByHash(filters, accessToken),
      ),
    [filters, accessToken],
  );

  const enabled = useMemo(() => {
    const { sort_by } = filters;

    return sort_by.every((sort) =>
      isValidSort(sort, sortConfig, (lowerCaseSort, validFields, validDirections) => {
        const hasAnyField = validFields.some((field) => lowerCaseSort.includes(field));
        const hasAnyDirection = validDirections.some((direction) => lowerCaseSort.includes(direction));
        return hasAnyField && hasAnyDirection;
      }),
    );
  }, [filters]);

  const query = useQuery<any, FetchError>([queryNames.search, filters], queryFn, { ...actualQueryOptions, enabled });

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching assets', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}

export function useAssetDetailsQuery(
  assetId: string,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () => queryFnFetchErrorWrapper(() => getAssetDetails(assetId, accessToken)),
    [assetId, accessToken],
  );

  const query = useQuery<any, FetchError>([queryNames.asset, assetId], queryFn, {
    ...actualQueryOptions,
    staleTime: 1000 * 60 * 30,
  });

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching asset details', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}

export function useAssetVisionQuery(
  assetId: string,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () => queryFnFetchErrorWrapper(() => getAssetVisionTags(assetId, accessToken)),
    [assetId, accessToken],
  );

  const query = useQuery<any, FetchError>([queryNames.assetVision, assetId], queryFn, {
    ...actualQueryOptions,
    staleTime: 1000 * 60 * 60,
  });

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching asset details', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}
