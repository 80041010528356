import * as React from 'react';

function FaceSearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
        <path d="M15.75 4.5H19.5V8.25" stroke="#002feb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.25 19.5H4.5V15.75" stroke="#002feb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.5 15.75V19.5H15.75" stroke="#002feb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 8.25V4.5H8.25" stroke="#002feb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 16.875C14.6924 16.875 16.875 14.6924 16.875 12C16.875 9.30761 14.6924 7.125 12 7.125C9.30761 7.125 7.125 9.30761 7.125 12C7.125 14.6924 9.30761 16.875 12 16.875Z" stroke="#010A2C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.1114 13.2186C13.8972 13.589 13.5894 13.8965 13.2188 14.1103C12.8482 14.3242 12.4279 14.4367 12 14.4367C11.5722 14.4367 11.1518 14.3242 10.7812 14.1104C10.4106 13.8966 10.1028 13.589 9.88861 13.2186" stroke="#010A2C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.1719 11.4922C10.4523 11.4922 10.6797 11.2648 10.6797 10.9844C10.6797 10.7039 10.4523 10.4766 10.1719 10.4766C9.89142 10.4766 9.66406 10.7039 9.66406 10.9844C9.66406 11.2648 9.89142 11.4922 10.1719 11.4922Z" fill="#010A2C"/>
        <path d="M13.8281 11.4922C14.1086 11.4922 14.3359 11.2648 14.3359 10.9844C14.3359 10.7039 14.1086 10.4766 13.8281 10.4766C13.5477 10.4766 13.3203 10.7039 13.3203 10.9844C13.3203 11.2648 13.5477 11.4922 13.8281 11.4922Z" fill="#010A2C"/>
    </svg>
  );
}

export default FaceSearchIcon;
