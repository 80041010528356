import cn from 'classnames';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import Loader from '../Loader';
import styles from './Button.module.scss';
import { v4 as uuidv4 } from 'uuid';

type ButtonProps = {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  mode?: 'primary' | 'secondary' | 'tertiary' | 'accept' | 'decline' | 'flat';
  size?: 'small' | 'large';
  outlined?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  icon?: React.ReactNode;
  tooltip?: string;
  datatest?:string
};

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  type = 'button',
  mode = 'primary',
  size = 'large',
  outlined,
  loading,
  disabled,
  icon,
  onClick = () => null,
  tooltip,
  datatest,
  ...rest
}) => {
  const uuid = uuidv4();

  return (
    <button
      type={type}
      onClick={onClick}
      className={cn([
        styles.Button,
        styles[mode],
        {
          [styles.outlined]: outlined,
          [styles.loading]: loading,
          [styles[size]]: size,
        },
        className,
      ])}
      disabled={disabled || loading}
      data-for={uuid}
      data-tip={tooltip}
      data-test={datatest}
      {...rest}
    >
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
      {icon && <span className={styles.icon}>{icon}</span>}
      {children && <span>{children}</span>}
      {tooltip && <ReactTooltip id={uuid} effect="solid" />}
    </button>
  );
};

export default Button;
