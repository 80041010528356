import * as React from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';

import Logo from 'src/components/Logo';
import Dropdown from 'src/components/Dropdown';
import { Bell, Settings, UserCircle } from 'src/common/icons';
import styles from './Header.module.scss';
import messages from './messages';
import useLogout from 'src/hooks/useLogout';
import Notifications from '../Notifications';

type HeaderProps = {};

export const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const auth = useAuthUser();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const logOut = useLogout();

  const username = auth() ? auth()!.username : '';

  const handleUserMenuClick = (key: string) => {
    switch (key) {
      case 'me':
      case 'settings':
        //history.push(`/${key}`);
        break;
      case 'logout':
        logOut();
        break;
      default:
        throw new Error(`Unknown navigation key: ${key}`);
    }
  };

  return (
    <div className={cn([styles.Header])}>
      <Logo />
      <nav>
        <NavLink to={`/assets`} activeClassName={styles.active}>
          <FormattedMessage {...messages.assets} />
        </NavLink>
        {/*<NavLink to={`/collections`} activeClassName={styles.active}>
          <FormattedMessage {...messages.collections} />
        </NavLink>
        <NavLink to={`/asset-transfers`} activeClassName={styles.active}>
          <FormattedMessage {...messages.assetTransfers} />
        </NavLink>*/}
      </nav>
      <div className={styles.icons}>
        {auth()?.role === 'ADMIN' && (
          <Link to={`/admin/settings`}>
            <Settings />
          </Link>
        )}
        {/*<Link to={`/notifications`}>
          <Bell />
        </Link>*/}
        <Notifications />
        <Dropdown
          dropdownAnchor="right"
          dropdownDirection="down"
          icon={<UserCircle />}
          onClick={handleUserMenuClick}
          options={[
            {
              key: 'me',
              value: username,
              noBorderBottom: false,
            },
            /*{
              key: 'settings',
              value: formatMessage(messages.preferences),
              noBorderBottom: true,
            },*/
            {
              key: 'logout',
              value: formatMessage(messages.logout),
              noBorderBottom: true,
            },
          ]}
          className={styles.dropdown}
        />
      </div>
    </div>
  );
};

export default Header;
