import * as React from 'react';
import cn from 'classnames';
import styles from './MetadataForm.module.scss';
import messages from './messages';
import SchemaForm from '../SchemaForm';
import { ISubmitEvent } from '@rjsf/core';
import validateFormData from '@rjsf/core/lib/validate';
import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';

type MetadataFormProps = {
  className?: string;
  schema?: any;
  metadata: any;
  onSubmit: ((e: ISubmitEvent<any>, nativeEvent: React.FormEvent<HTMLFormElement>) => any) | undefined;
  onCancel: () => void;
};

const inferType = (value: any): string => {
  if (typeof value === 'string') return 'string';
  if (typeof value === 'number') return 'number';
  if (typeof value === 'boolean') return 'boolean';
  if (Array.isArray(value)) return 'array';
  return 'string';
};
const generateSchemaFromData = (title: string, data: any) => {
  const keys = Object.keys(data);

  const schema: any = {
    type: 'object',
    properties: {},
    title,
  };

  keys.forEach((key: string) => {
    schema.properties[key] = {
      type: inferType(data[key]),
      title: key,
    };
  });

  return schema;
};

const appendNewProperties = (schema: any, metadata: any) => {
  const updatedSchema = { ...schema };

  Object.keys(metadata).forEach((key: string) => {
    if (!updatedSchema.properties[key]) {
      if(Array.isArray(metadata[key])){
       
        updatedSchema.properties[key]={
          type: inferType(metadata[key]),
        title: key,
        description: `Generated property for ${key}`,
        filespin_properties: {
          title: { en: key },
          hint: { en: `Generated property for ${key}` },
          placeholder: { en: `Enter ${key}` },
          searchable: true,
          filterable: false,
          facetable: false,
          ui: {
            order: Object.keys(updatedSchema.properties).length + 2,
            readonly: false,
            disabled: false,
            hidden: false,
          },
        },
        items:{
          title: "List element",
          type: "string",
          description: "element",
          hint: "hint",
          minLength: 1,
          maxLength: 100
      }
        }
      }else{
      updatedSchema.properties[key] = {
        type: inferType(metadata[key]),
        title: key,
        description: `Generated property for ${key}`,
        filespin_properties: {
          title: { en: key },
          hint: { en: `Generated property for ${key}` },
          placeholder: { en: `Enter ${key}` },
          searchable: true,
          filterable: false,
          facetable: false,
          ui: {
            order: Object.keys(updatedSchema.properties).length + 2,
            readonly: false,
            disabled: false,
            hidden: false,
          },
        },
        minLength: 1,
        maxLength: 200,
      };
    }
  }
  });

  return updatedSchema;
};

const ensureSchemaVersion = (schema: any) => {
  const updatedSchema = { ...schema };

  delete updatedSchema.$schema;

  // Add the Draft-07 schema identifier
  updatedSchema.$schema = 'http://json-schema.org/draft-07/schema#';

  return updatedSchema;
};
export const MetadataForm: React.FC<MetadataFormProps> = ({
  className,
  schema,
  metadata,
  onSubmit,
  onCancel,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  let formSchema = schema;
  if (!schema) {
    const schemaTitle = formatMessage(messages.customSchema);
    formSchema = generateSchemaFromData(schemaTitle, metadata);
  } else {
    formSchema = appendNewProperties(schema, metadata);
    formSchema = ensureSchemaVersion(formSchema);
    const validation = validateFormData(metadata, formSchema);
    if (validation.errors.length > 0) {
      formSchema = appendNewProperties(schema, metadata);
      Sentry.captureMessage(`Schema Errors: ${validation.errors.toString()}`, Sentry.Severity.Error);
    }
  }

  return (
    <div className={cn([styles.MetadataForm, className])}>
      <SchemaForm schema={formSchema} formData={metadata} onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
};

export default MetadataForm;
