import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { useSettingsQuery } from 'src/api/queries/userQueries';
import { pickerModeAtom } from 'src/shared/pickerMode';
import { config } from 'src/utils/config';

const SCRIPT_ID = 'filespin-picker-script';

const FileSpinPickerProvider: React.FC<any> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const setMode = useUpdateAtom(pickerModeAtom);

  // useSettingsQuery(undefined, {
  //   enabled: isAuthenticated(),
  // });

  const loadPicker = () => {
    const existingScript = document.getElementById(SCRIPT_ID);

    if (existingScript) return;

    const script = document.createElement('script');
    script.id = SCRIPT_ID;
    script.src = config.filespinLoader;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  };

  useEffect(() => {
    loadPicker();

    return () => setMode(undefined);
  }, []);

  return children;
};

export default FileSpinPickerProvider;
