import cn from 'classnames';
import * as React from 'react';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  className?: string;
  position?: 'top' | 'left' | 'right' | 'bottom';
  isBlock?: boolean;
  delayed?: boolean;
  text: string;
  moveTextOnHover?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  className,
  position = 'top',
  isBlock,
  delayed = false,
  text,
  moveTextOnHover=false,
  ...rest
}) => {
  return (
    <div
      className={cn([
        styles.Tooltip,
        styles[position],
        { [styles.blocky]: isBlock },
        { [styles.delayed]: delayed },
        { [styles.moveText]: moveTextOnHover },
        className,
      ])}
      {...rest}
    >
      <span className={styles.bubble}>{text}</span>
      {children}
    </div>
  );
};

export default Tooltip;
