import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    id: 'pages.modal.addtobasket',
    defaultMessage: 'Add to basket',
  },
  downloadContent: {
    id: 'pages.modal.downloadContent',
    defaultMessage: 'Download',
  },
  replaceContent: {
    id: 'pages.modal.replaceContent',
    defaultMessage: 'Replace Content',
  },
  editImage: {
    id: 'pages.modal.editImage',
    defaultMessage: 'Edit Image',
  },
  tools: {
    id: 'pages.modal.Tools',
    defaultMessage: ' Tools',
  },
  cancelEditImage: {
    id: 'pages.modal.cancelEditImage',
    defaultMessage: 'Close Edit Mode',
  },
  back: {
    id: 'pages.modal.back',
    defaultMessage: 'Back',
  },
  metadata: {
    id: 'pages.modal.metadata',
    defaultMessage: 'Metadata',
  },
  core: {
    id: 'pages.modal.core',
    defaultMessage: 'Core',
  },
  conversions: {
    id: 'pages.modal.conversions',
    defaultMessage: 'Conversions',
  },
  addon: {
    id: 'pages.modal.addOn',
    defaultMessage: 'Add-on',
  },
  customMetadata: {
    id: 'pages.modal.customMetadata',
    defaultMessage: 'Custom Metadata',
  },
  customMetadataContent: {
    id: 'pages.modal.customMetadataContent',
    defaultMessage: 'Dynamically create and display product images that highlight the product by perfectly blending.',
  },
  goBack: {
    id: 'pages.modal.goBack',
    defaultMessage: 'Go Back',
  },
  saveConversion: {
    id: 'pages.modal.saveConversion',
    defaultMessage: 'Save into Asset as a Conversion',
  },
  saveNewAsset: {
    id: 'pages.modal.saveNewAsset',
    defaultMessage: 'Save into New Asset ',
  },
  assetUpdateDialogTitle: {
    id: 'pages.modal.assetUpdateDialogTitle',
    defaultMessage: 'Edited Copy of Image',
  },
  replaceOriginalImageNote: {
    id: 'pages.modal.replaceOriginalImageNote',
    defaultMessage: `WARNING: This will remove all the conventions and start all the Conventions.`,
  },
  replaceOriginalImage: {
    id: 'pages.modal.replaceOriginalImage',
    defaultMessage: `Replace Original {assetType}`,
  },
  addAsAConversion: {
    id: 'pages.modal.addAsAConversion',
    defaultMessage: 'Add as a Conversion',
  },
  downloadEditedCopy: {
    id: 'pages.modal.downloadEditedCopy',
    defaultMessage: 'Download Edited Copy',
  },
  addonNotEnabled: {
    id: 'pages.modal.addonNotEnabled',
    defaultMessage: 'Addon Not Enabled Please Contact Support',
  },
  addAsNewAsset: {
    id: 'pages.modal.addAsNewAsset',
    defaultMessage: 'Add As New Asset',
  },
  cancel: {
    id: 'pages.modal.cancel',
    defaultMessage: 'Cancel',
  },
  addFreeFormData: {
    id: 'pages.modal.addFreeFormData',
    defaultMessage: 'Add Free Form Data',
  },
  assetMetadataSetupInfo: {
    id: 'pages.modal.assetMetadataSetupInfo',
    defaultMessage:
      "It seems that you don't have any metadata nor any schema for your metadata, choose one of the two:",
  },
  selectSchema: {
    id: 'pages.modal.selectSchema',
    defaultMessage: 'Select a schema for your metadata:',
  },
  submit: {
    id: 'pages.modal.submit',
    defaultMessage: 'Submit',
  },
  addField: {
    id: 'pages.modal.addField',
    defaultMessage: 'Add Field',
  },
  successfullyReplacedOriginalImage: {
    id: 'pages.modal.successfullyReplacedOriginalImage',
    defaultMessage:
      'Sucessfuly replaced original asset, this might take a while to update, This will also remove all the conventions and start the reprocessing.',
  },
  newAssetcreated: {
    id: 'pages.modal.newAssetcreated',
    defaultMessage: 'The new asset is created successfully',
  },
  subtitleUpdated: {
    id: 'pages.modal.subtitleUpdated',
    defaultMessage: 'Subtitle updated successfully',
  },
  savedConversion: {
    id: 'pages.modal.savedConversion',
    defaultMessage: `Sucessfully saved {conversionName} as an asset conversion`,
  },
  addedNewAsset: {
    id: 'pages.modal.addedNewAsset',
    defaultMessage: `Sucessfully Added as New Asset in the Dashboard`,
  },
  replaceOriginal: {
    id: 'pages.modal.replaceOriginal',
    defaultMessage: `Sucessfuly replaced original {type}, this might take a while to update.`,
  },
  editConfirmDialogTitle: {
    id: 'pages.modal.editConfirmDialogTitle',
    defaultMessage: 'Do you want to edit another conversion?',
  },
  editConfirmDialogMessage: {
    id: 'pages.modal.editConfirmDialogMessage',
    defaultMessage: 'You will lose all your changes if you proceed.',
  },
  addedToBasket: {
    id: 'pages.modal.addedToBasket',
    defaultMessage: 'Sucessfully added asset to basket',
  },
  removedFromBasket: {
    id: 'pages.modal.removedFromBasket',
    defaultMessage: 'Sucessfully removed asset from basket',
  },
});
