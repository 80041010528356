import cn from 'classnames';
import { useSettingsQuery } from 'src/api/queries/userQueries';
import { AdminSettingsSection, Button, Paper, SaveChangesBar, SettingsNavigation } from 'src/components';
import styles from './styles.module.scss';
import { useAtom } from 'jotai';
import { useMemo, useState, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import { addonsSettingsAtom } from 'src/shared/settings/addons';
import { ACTION_TYPES } from 'src/shared/settings/base';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import { useAlerts } from 'src/common/AlertManager';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { updateAddonSettingsApi } from 'src/api/functions/userApi';
import SvgWrench from 'src/common/icons/Wrench';

export const AddonsSettings = () => {
  const { isLoading } = useSettingsQuery();
  const { showSuccess, showAlert } = useAlerts();
  const accessToken = useAccessToken();

  const [addonsSettings, updateAddonsSettings] = useAtom(addonsSettingsAtom);

  const [showSettingsFor, setShowSettingsFor] = useState('');
  const [showSettingsEditor, setShowSettingsEditor] = useState(false);
  const [hookValue, setHookValue] = useState('{}');
  const [initialHookValue, setInitialHookValue] = useState('{}');
  const [showSettingsAddons, setShowSettingsAddons] = useState([
    'FACE_RECOGNITION',
    'EMOTION_CLASSIFICATION',
    'SCENE_CLASSIFICATION',
  ]);

  useEffect(() => {
    if (showSettingsFor) {
      const initialValue = addonsSettings[showSettingsFor]?.settings?.['event-hooks']
        ? JSON.stringify(addonsSettings[showSettingsFor]?.settings?.['event-hooks'], null, 2)
        : '{}';
      setHookValue(initialValue);
      setInitialHookValue(initialValue);
    }
  }, [showSettingsFor, addonsSettings]);

  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const addons = useMemo(() => {
    if (!addonsSettings) return [];

    return Object.entries(addonsSettings).map(([key, value]: [string, any]) => ({ key, ...value }));
  }, [addonsSettings]);

  const addedAddons = useMemo(() => {
    return addons.filter((addon) => addon.added);
  }, [addons]);

  const hasChanges = useMemo(() => {
    if (isJsonString(hookValue) && isJsonString(initialHookValue)) {
      return JSON.stringify(JSON.parse(hookValue)) !== JSON.stringify(JSON.parse(initialHookValue));
    }
    return hookValue === initialHookValue;
  }, [hookValue, initialHookValue]);
  if (isLoading) return null;


  const handleAddonUpdate = (addon: string, value: any) =>
    updateAddonsSettings({
      type: 'updateState',
      settings: 'addons_settings',
      payload: {
        [addon]: {
          enabled: value,
        },
      },
    });

  const handleAddAddon = (addon: string) => {
    updateAddonsSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: {
        [addon]: {
          added: true,
        },
      },
    });
  };

  const handleRemoveAddon = (addon: string) => {
    updateAddonsSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: {
        [addon]: {
          added: false,
        },
      },
    });
  };

  const onChange = (newValue: string) => {
    setHookValue(newValue);
  };

  // Function to handle format click
  const handleFormatClick = () => {
    try {
      const parsedValue = JSON.parse(hookValue);
      setHookValue(JSON.stringify(parsedValue, null, 2));
    } catch (error: any) {
      console.error('Invalid JSON format:', error.message);
      showAlert(`Invalid JSON format: ${error.message}`);
    }
  };
  const disabledKeys = ['ON_DEMAND_IMAGE', 'VIDEO_TRANSCODE', 'IMAGE_DEEPZOOM'];
  //on save
  const saveAddonSettings = () => {
    const addon = showSettingsFor;
    const data = { 'event-hooks': JSON.parse(hookValue) };

    updateAddonSettingsApi(data, addon, accessToken).then((value) => {
      if (value?.error) {
        showAlert(`Failed to update settings`);
      } else {
        showSuccess(`Settings Updated`);
        setInitialHookValue(hookValue);
        updateAddonsSettings({
          type: ACTION_TYPES.UPDATE_STATE,
          payload: {
            [addon]: {
              settings: data,
            },
          },
        });
      }
    });
  };

  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar />

      <div className={styles.container}>
        <div className={styles.addonsListContainer}>
          <h3>Available Addons</h3>
          <div className={styles.addonsList}>
            {addons.map((addon) => (
              <div key={addon.key}>
                <p>{addon.title}</p>
                <Button
                  mode="tertiary"
                  size="small"
                  onClick={() => (addon.added ? handleRemoveAddon(addon.key) : handleAddAddon(addon.key))}
                >
                  {addon.added ? 'Remove' : 'Add'}
                </Button>
              </div>
            ))}
          </div>
        </div>

        <div className={cn(styles.content, styles.webhookOptions)}>
          <AdminSettingsSection title="Enabled Addons" className={styles.clearUpperMargin}>
            {addedAddons.map((addon) => (
              <Paper key={addon.key} className={styles.webhookOption}>
                <ReactSwitch
                  checked={addon.enabled}
                  onChange={(checked: boolean) => handleAddonUpdate(addon.key, checked)}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  disabled={disabledKeys.includes(addon.key)}
                  onColor="#2e53e3"
                />
                <label style={{ userSelect: 'none' }}>{addon.title}</label>
                <div
                  className={styles.gearHolder}
                  style={{
                    backgroundColor:
                      showSettingsFor === addon.key && showSettingsEditor === true
                        ? 'var(--color-prime-500)'
                        : 'transparent',
                  }}
                >
                  {showSettingsAddons.includes(addon.key) && (
                    <SvgWrench
                      style={{
                        cursor: 'pointer',
                        color: showSettingsFor === addon.key && showSettingsEditor === true ? '#fff' : '#000',
                      }}
                      onClick={() => {
                        if (showSettingsFor === addon.key) {
                          setShowSettingsEditor((prev) => !prev);
                        } else {
                          setShowSettingsFor(addon.key);
                          setShowSettingsEditor(true);
                        }
                      }}
                    />
                  )}
                </div>
              </Paper>
            ))}
          </AdminSettingsSection>
        </div>
        <div className={cn(styles.content_settings)}>
          {showSettingsEditor && (
            <>
              <h2 className={styles.title}>Addon Settings</h2>
              <div className={styles.footerNotes}>
                {' '}
                Please refer to the documentation or reach out to Support to update this field{' '}
              </div>
              <AceEditor
                mode="json"
                theme="chrome"
                onChange={onChange}
                fontSize={14}
                editorProps={{ $blockScrolling: true }}
                className={styles.editor}
                value={hookValue ? hookValue : '{}'}
              />
              {/* <div className={styles.formatBtn} onClick={handleFormatClick}>Format</div> */}

              <div className={styles.btnHolder}>
                <Button
                  mode="tertiary"
                  size="small"
                  className={styles.AddonSettingBtn}
                  onClick={() => {
                    setHookValue(initialHookValue);
                    setShowSettingsEditor(false);
                    setShowSettingsFor('');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  mode="primary"
                  size="small"
                  className={styles.AddonSettingBtn}
                  onClick={saveAddonSettings}
                  disabled={!hasChanges}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
