import * as React from 'react';
import Button from 'src/components/Button';
import Camera from 'src/common/icons/Camera';
import Folder from 'src/common/icons/Folder';
import Info from 'src/common/icons/Info';
import X from 'src/common/icons/X';

import styles from './FloatingFaceSearch.module.scss';

import { Camera as CameraPro, CameraType } from 'react-camera-pro';

type FloatingFaceSearchProps = {
  image: any;
  onClickCamera: (image: any) => void;
  onClickBrowse: (image: any) => void;
  onClickCancel: () => void;
  foldableFaceFilter?:React.ReactNode
};

const FloatingFaceSearch = ({ onClickCamera, onClickBrowse, onClickCancel, image ,foldableFaceFilter }: FloatingFaceSearchProps) => {
  const [isCameraAccessible, setIsCameraAccessible] = React.useState<boolean>(false);
  const [permissionStatus, setPermissionStatus] = React.useState<PermissionState | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const camera = React.useRef<CameraType>(null);
  const streamRef = React.useRef<MediaStream | null>(null); // Store the camera stream
  const fileInputRef = React.useRef<HTMLInputElement | null>(null); // Ref for file input

  React.useEffect(() => {
    let permission: PermissionStatus | null = null;

    const handlePermissionChange = async () => {
      if (!permission) return;

      setPermissionStatus(permission.state);
      if (permission.state === 'granted' && !streamRef.current) {
        const stream = await requestCameraStream();
        if (stream) {
          setIsCameraAccessible(true);
          streamRef.current = stream;
        }
      } else if (permission.state !== 'granted' && streamRef.current) {
        stopCameraStream();
        setIsCameraAccessible(false);
      }
    };

    const checkCameraPermission = async () => {
      try {
        permission = await navigator.permissions.query({ name: 'camera' as PermissionName });
        setPermissionStatus(permission.state);

        if (permission.state === 'granted' && image === null) { // Only access camera if image is null
          const stream = await requestCameraStream();
          if (stream) {
            setIsCameraAccessible(true);
            streamRef.current = stream;
          }
        } else {
          setIsCameraAccessible(false);
        }

        // Set up listener for permission changes
        permission.onchange = handlePermissionChange;
      } catch (error) {
        console.error('Error checking camera permissions:', error);
        setIsCameraAccessible(false);
      } finally {
        setLoading(false);
      }
    };

    const requestCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        return stream;
      } catch (error) {
        console.error('Error accessing camera stream:', error);
        return null;
      }
    };

    const stopCameraStream = () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
    };

    // Check permissions on mount
    checkCameraPermission();

    // Cleanup function to stop the camera stream and remove permission onchange listener
    return () => {
      stopCameraStream();
      if (permission) {
        permission.onchange = null; // Remove the onchange listener
      }
    };
  }, [image]); // Add image to the dependency array

  const requestCameraAccess = async () => {
    setLoading(true); // Optimistic loading state
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setIsCameraAccessible(true);
        streamRef.current = stream;
      }
    } catch (error) {
      console.error('Error requesting camera access:', error);
      setIsCameraAccessible(false);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevents the floating window from closing when clicking inside it.
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const imageData = e.target?.result as string;
  
        // Create an off-screen canvas to flip the image
        const img = new Image();
        img.src = imageData;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (!ctx) return;
  
          canvas.width = img.width;
          canvas.height = img.height;
  
          // Flip the image horizontally
          ctx.translate(canvas.width, 0);
          ctx.scale(-1, 1);
          ctx.drawImage(img, 0, 0);
  
          // Convert the canvas back to a base64 image
          const flippedImageData = canvas.toDataURL("image/jpeg");
          onClickBrowse(flippedImageData);
        };
      };
      reader.readAsDataURL(file); // Convert file to base64 format for preview
    }
  
    // Reset the file input value so that selecting the same file will trigger the onChange event
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  
  

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };

  return (
    <div className={styles.faceSearchFloatingWindow} onMouseDown={handleMouseDown}>
      <div className={styles.faceSearchDiv}>
      <div className={styles.faceDeviceView}>
        {!loading && !image && isCameraAccessible && (
          <CameraPro
            ref={camera}
            facingMode="user"
            errorMessages={{
              noCameraAccessible: '',
              permissionDenied: '',
              switchCamera: '',
              canvas: '',
            }}
          />
        )}
        {!loading && image && <img alt="image_view" src={image} className={styles.imageHolder} />}
        {!loading && isCameraAccessible && image === null && ( // Access camera only when image is null
          <Button
            className={styles.clickPicBtn}
            icon={<Camera style={{ fontSize: 26 }} />}
            onClick={() => {
              if (camera.current) {
                const photo = camera.current.takePhoto();
                onClickCamera(photo as string);
              }
            }}
          />
        )}
        {image && (
          <Button
            className={styles.clickPicBtn}
            icon={<X style={{ fontSize: 20 }} />}
            onClick={() => {
              setLoading(true)
              onClickCancel();
            }}
          />
        )}
        {!loading && !isCameraAccessible && permissionStatus !== 'denied' && permissionStatus !== 'prompt' && !image && (
          <div className={styles.noCameraAccessTxt}>
            <Info stroke="#FFFFFF" fontSize={50} style={{ marginBlock: 10 }} />
            <div>Unable to access the camera. Please ensure your device is connected <br/> and camera permissions are granted.</div>
          </div>
        )}
        {!loading && permissionStatus === 'prompt' && !image && (
          <Button className={styles.requestPermissionBtn} onClick={requestCameraAccess}>
            Grant Camera Access
          </Button>
        )}
        {!loading && permissionStatus === 'denied' && !image && (
          <div className={styles.noCameraAccessTxt}>
            <Info stroke="#FFFFFF" fontSize={50} style={{ marginBlock: 10 }} />
            <div>Camera access is blocked. <br/> Please enable camera permissions in your browser settings.</div>
          </div>
        )}
      </div>
      <hr className={styles.line} />
      
      <Button
        className={styles.browseBtn}
        icon={<Folder style={{ fontSize: 18, marginRight: -5 }} />}
        onClick={handleBrowseClick}
      >
        Browse from files
      </Button>
      <input
        id="file-upload"
        type="file"
        accept="image/jpeg, image/png"
        className={styles.fileInput} // Style this input to be invisible
        onChange={handleFileChange}
        ref={fileInputRef} // Attach the ref here
        style={{ display: 'none' }} // Hide the input element
      />
      </div>
      {foldableFaceFilter && (
        <div className={styles.filters}>{foldableFaceFilter}</div>
      )}
      
    </div>
  );
};

export default FloatingFaceSearch;
//