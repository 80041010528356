import * as React from 'react';

function CloseCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="#002feb"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
        <path d="M12.0002 21C16.9708 21 21.0002 16.9706 21.0002 12C21.0002 7.02944 16.9708 3 12.0002 3C7.02968 3 3.00024 7.02944 3.00024 12C3.00024 16.9706 7.02968 21 12.0002 21Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.0002 9L9.00024 15" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.0002 15L9.00024 9" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default CloseCircle;
