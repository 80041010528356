/**
 *
 * This file is part of Filespin.io (https://filespin.io)
 *
 **/

import { useAccessToken } from 'src/hooks/useAccessToken';
import useRoleManager from 'src/hooks/useRoleManager';
import { deleteAsset, getAssetMediaUrl, restoreAsset } from 'src/api/functions/assetsApi';
import Checkbox from '../Checkbox';
import { PlayBox, FileArrowDown, TrashSimple, Gear, Pencil, Stack, WarningCircle } from 'src/common/icons';
import { isPreviewPlayable } from 'src/utils/previews';
import { humanFileSize } from 'src/utils/transform';
import styles from './ListingRow.module.scss';
import SvgArrowCounterClockwise from 'src/common/icons/ArrowCounterClockwise';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import AssetPreview from '../AssetPreview';
import { useCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useCollectionQuery } from 'src/api/queries/collectionsQueries';
import { queryNames } from 'src/api/utils/queryNames';
import { CollectionResponse } from 'src/types/TCollection';

import * as React from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useQueryClient } from 'react-query';
import cn from 'classnames';
import { detectExtension } from 'mimetypes';
import { useAtom } from 'jotai';

interface ListingRowProps {
  // Style class name
  className?: string;

  // Asset id
  id: string;

  // Siblings
  siblings?: string[];

  // Image url for the asset
  imageUrl: string;

  // Name of the asset
  name: string;

  // Size of the asset
  size: string;

  // Upload time of the asset
  uploadTime: string;

  // Content type of the asset (e.g. image/jpeg)
  contentType: string;

  // Is asset trashed
  trashed: boolean;

  // Status of the asset
  status: string;

  // Is asset selected
  isSelected?: boolean;

  error?: any;

  // On select change handler
  onSelectChange?: React.ChangeEventHandler<HTMLInputElement> & ((value: React.ChangeEvent<HTMLInputElement>) => void);
}

/**
 *
 * @name ListingRow
 *
 *
 * @param {string} className Style class name
 * @param {string} id Asset id
 * @param {string[]} siblings
 * @param {string} imageUrl
 * @param {string} name
 * @param {string} size
 * @param {string} uploadTime
 * @param {string} contentType
 * @param {boolean} trashed
 * @param {string} status
 * @param {boolean} isSelected
 * @param {object} error
 * @param onSelectChange
 * @param rest
 *
 * @returns JSX Element
 *
 *
 * @description
 *
 * This component is used to render a row in the listing view. It is used in the
 * Assets and Collections pages. It is also used in the Trash page.
 *
 *
 **/
export const ListingRow: React.FC<ListingRowProps> = ({
  className,
  id,
  siblings,
  imageUrl,
  name,
  size,
  trashed,
  uploadTime,
  contentType,
  isSelected,
  onSelectChange,
  status,
  error,
  ...rest
}) => {
  const location = useLocation();

  return (
    <div className={cn([styles.ListingRow, className])}>
      <div className={styles.checkbox}>
        <Checkbox checked={isSelected} onChange={onSelectChange} />
      </div>

      {!trashed ? (
        <Link to={{ pathname: `/asset/${id}`, state: { background: location, siblings } }}>
          <div className={styles.thumbWrapper}>
            <AssetPreview src={imageUrl} name={name} status={status} contentType={contentType} />
            {contentType && status !== 'NOT_READY' && isPreviewPlayable(contentType) && (
              <div className={styles.playbox}>
                <PlayBox />
              </div>
            )}
          </div>
        </Link>
      ) : (
        <div className={styles.thumbWrapper}>
          <AssetPreview src={imageUrl} name={name} status={status} contentType={contentType} />
          {contentType && status !== 'NOT_READY' && isPreviewPlayable(contentType) && (
            <div className={styles.playbox}>
              <PlayBox />
            </div>
          )}
        </div>
      )}

      <div className={styles.title}>
        <div className={styles.fileNameContainer}>
          {status === 'NOT_READY' && (
            <div className={styles.processingAsset}>
              <Gear /> <span>Processing</span>
            </div>
          )}
          {error?.original && (
            <button data-tip={error?.original} className={styles.errorAsset}>
              <WarningCircle /> <span>ERROR</span>
              <ReactTooltip effect="solid" />
            </button>
          )}
          {!trashed ? (
            <Link
              to={{
                pathname: `/asset/${id}`,
                state: { background: location, siblings },
              }}
            >
              {name}
            </Link>
          ) : (
            <span>{name}</span>
          )}
        </div>
        <small>
          {id}
          <br />
          {detectExtension(contentType).toUpperCase()} {humanFileSize(Number(size))}
        </small>
      </div>

      <div>{new Date(uploadTime).toLocaleString()}</div>
      <ToolBox id={id} trashed={trashed} />
    </div>
  );
};

interface ToolBoxProps {
  id: string;
  trashed: boolean;
  siblings?: string[];
}

const ToolBox = React.memo((props: ToolBoxProps) => {
  const history = useHistory();
  const downloadingRef = React.useRef('');
  const accessToken = useAccessToken();
  const auth = useAuthUser();
  const [deletedAssets, updateDeletedAssets] = useAtom(deletedAssetsAtom);
  const [isDeletingAsset, setIsDeletingAsset] = React.useState(false);
  const [isRestoringAsset, setIsRestoringAsset] = React.useState(false);
  const location = useLocation();

  const { showSuccess, showAlert } = useAlerts();

  const { getErrorMessage } = useErrorMessages();

  const { isUser } = useRoleManager();

  const queryClient = useQueryClient();

  const { data: basketData } = useCollectionQuery(undefined, {
    staleTime: 1000 * 60 * 5,
  });

  const basketMutation = useCollectionMutation(Number(basketData?.data?.id), {
    onError: (error, newData, context: any) => {
      queryClient.setQueryData([queryNames.collection, null], context.previousData);

      showAlert(getErrorMessage(error.message));
    },
    onMutate: (data) => {
      /*showInfo(`Requesting update...`);*/
      const previousData = queryClient.getQueryData<CollectionResponse>([queryNames.collection, null]);
      queryClient.setQueryData<CollectionResponse | undefined>(
        [queryNames.collection, null],
        (old: CollectionResponse | undefined) => {
          if (typeof old !== 'undefined') {
            old.data.assets = (old?.data?.assets ?? [])
              .filter((id) => !(data?.deletions ?? []).includes(id))
              .concat(data?.additions ?? []);
          }
          return old;
        },
      );
      return { previousData };
    },
    onSuccess: () => {
      showSuccess('Successfully added asset to basket');
    },
  });

  const handleEditAsset = () => history.push(`/asset/${props.id}`, { background: location, sublings: props.siblings });

  const getMediaUrl = async () => {
    if (downloadingRef.current === '') {
      const url = await getAssetMediaUrl(props.id, 'original', auth()!.accessId, 'download', accessToken);
      downloadingRef.current = url;
      window.open(url);
    } else {
      window.open(downloadingRef.current);
    }
  };

  const addToBasket = () => {
    if (basketMutation.isLoading) return;
    basketMutation.mutateAsync({ additions: [props.id] });
  };

  const handleRestoreAsset = async () => {
    if (isRestoringAsset) return;

    setIsRestoringAsset(true);
    try {
      const response = await restoreAsset(props.id, accessToken);

      if (typeof response.status === 'number' && ![200, 202].includes(response.status)) {
        throw response;
      }

      showSuccess(`Successfully restored asset.`);
    } catch (e: any) {
      showAlert(getErrorMessage(e.message));
    } finally {
      setIsRestoringAsset(false);
    }
  };

  const handleDeleteAsset = async () => {
    if (isDeletingAsset) return;

    setIsDeletingAsset(true);

    try {
      const response = await deleteAsset(props.id, accessToken);
      if (typeof response.status === 'number' && ![200, 202].includes(response.status)) {
        throw response;
      }

      showSuccess(`Successfully deleted asset.`);
      updateDeletedAssets([...deletedAssets, props.id]);
    } catch (e: any) {
      console.log(e);
      showAlert(getErrorMessage(e.message));
    } finally {
      setIsDeletingAsset(false);
    }
  };

  return (
    <div className={styles.actions}>
      {!isUser && !props.trashed && (
        <button data-tip="Edit" onClick={handleEditAsset} data-test="click-asset-button">
          <Pencil />
          <ReactTooltip effect="solid" />
        </button>
      )}

      {!props.trashed && (
        <button data-tip="Download" onClick={getMediaUrl}>
          <FileArrowDown />
          <ReactTooltip effect="solid" />
        </button>
      )}

      {!props.trashed && (
        <button data-tip="Add to Basket" onClick={addToBasket}>
          <Stack />
          <ReactTooltip effect="solid" />
        </button>
      )}

      {!isUser &&
        (props.trashed ? (
          <button data-tip="Restore Asset" onClick={handleRestoreAsset}>
            <SvgArrowCounterClockwise />
            <ReactTooltip effect="solid" />
          </button>
        ) : (
          <button data-tip="Delete Asset" onClick={handleDeleteAsset}>
            <TrashSimple />
            <ReactTooltip effect="solid" />
          </button>
        ))}
    </div>
  );
});

export default ListingRow;
