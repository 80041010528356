import * as React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useAtom } from 'jotai';

import { filtersVisibleAtom } from 'src/shared/filters';
import { SlidersHorizontal, MagnifyingGlass, ArrowsClockwise } from 'src/common/icons';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import styles from './SearchBar.module.scss';
import messages from './messages';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { isRefetchingAtom, refreshAtom } from 'src/shared/refresh';
import Dropdown from '../Dropdown';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import { TSorting, TSortingOptions } from 'src/types/TSorting';
import { SelectInputOption } from '../Dropdown/Dropdown';
import FaceSearchIcon from 'src/common/icons/FaceSearch';
import Camera from 'src/common/icons/Camera';
import Folder from 'src/common/icons/Folder';
import Tooltip from '../Tooltip';
import FloatingFaceSearch from './FloatingFaceSearch';
import CloseCircle from 'src/common/icons/CloseCircle';
import FaceSearchIconDisabled from 'src/common/icons/FaceSearchDisabled';

type SearchBarProps = {
  className?: string;
  hideFilters?: boolean;
  search?: string;
  sortKeys?: TSortingOptions[];
  onSearch?: (searchTerm: string) => void;
  onSearchFace?: (img: string) => void;
  onSearchFaceCancel?: () => void;
  searchButton?: boolean;
  searchFace?: boolean;
  refreshButton?: boolean;
  filterButton?: boolean;
  dropDownOptions?: SelectInputOption[];
  onClickDropdown?: React.Dispatch<React.SetStateAction<string>>;
  dropDownSelectedKeys?: string;
  onClickSearchButton?: (searchTerm: string) => void;
  onSelectSort?: (sorting: TSorting) => void;
  onClickFiltersButton?: any;
  foldableFaceFilter?: React.ReactNode;
  faceSearchEnabled?: boolean;
  setFaceSearchEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DEFAULT_DIRECTION = 'DESC';

export const SearchBar = ({
  className,
  hideFilters,
  search = '',
  sortKeys,
  searchButton,
  searchFace = false,
  onSearch,
  onSelectSort,
  onClickSearchButton,
  onSearchFace = () => {},
  onSearchFaceCancel = () => {},
  onClickFiltersButton,
  refreshButton = true,
  filterButton = true,
  dropDownOptions,
  dropDownSelectedKeys,
  onClickDropdown,
  foldableFaceFilter,
  faceSearchEnabled,
  setFaceSearchEnabled,
  ...rest
}: SearchBarProps) => {
  const { formatMessage } = useIntl();

  const [searchTerm, setSearchTerm] = React.useState(search);
  const [sorting, setSorting] = React.useState<TSorting>([sortKeys ? sortKeys[0].key : '', DEFAULT_DIRECTION]);
  const [isFaceSearchOpen, setIsFaceSearchOpen] = React.useState(false);
  const [image, setImage] = React.useState<string | null>(null);

  const refreshUpdate = useUpdateAtom(refreshAtom);
  const isRefetching = useAtomValue(isRefetchingAtom);
  const [, updateDeletedAssets] = useAtom(deletedAssetsAtom);

  const handleRefresh = () => {
    updateDeletedAssets([]);
    refreshUpdate((c) => c + 1);
  };

  const toggleFaceSearch = (e: React.MouseEvent) => {
    setIsFaceSearchOpen((prev) => !prev);
  };

  const onSort = (key: string) => {
    let newSorting: TSorting;

    if (sorting[0] === key) {
      newSorting = [key, sorting[1] === DEFAULT_DIRECTION ? 'ASC' : 'DESC'];
    } else {
      newSorting = [key, DEFAULT_DIRECTION];
    }

    setSorting(newSorting);
    onSelectSort && onSelectSort(newSorting);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    onSearch && onSearch(value);
  };

  const handleClickSearchButton = () => onClickSearchButton && onClickSearchButton(searchTerm);

  const handleKeyDown = (key: string) => key === 'Enter' && handleClickSearchButton();

  React.useEffect(() => {
    return () => {
      console.log('unmounting search component...');
    };
  }, []);

  React.useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  // const SCROLL_THRESHOLD = 5; // Define a threshold in pixels
  // let lastScrollY = 0; // To track the last scroll position

  // React.useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     // Check if the click was outside both the floating window and the face search icon
  //     const faceSearchWrapper = document.querySelector(`.${styles.faceSearchWrapper}`);
  //     const faceIcon = document.querySelector(`.${styles.faceIcon}`);

  //     if (
  //       faceSearchWrapper &&
  //       !faceSearchWrapper.contains(event.target as Node) &&
  //       faceIcon &&
  //       !faceIcon.contains(event.target as Node)
  //     ) {
  //       setIsFaceSearchOpen(false);
  //     }
  //   };

  //   const handleScroll = () => {
  //     const currentScrollY = window.scrollY; // Get the current scroll position

  //     // Check if the scroll exceeds the threshold
  //     if (Math.abs(currentScrollY - lastScrollY) > SCROLL_THRESHOLD) {
  //       setIsFaceSearchOpen(false); // Close the floating window if threshold exceeded
  //     }

  //     // Update the last scroll position
  //     lastScrollY = currentScrollY;
  //   };

  //   if (isFaceSearchOpen) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //     document.addEventListener('scroll', handleScroll);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     document.removeEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     document.removeEventListener('scroll', handleScroll);
  //   };
  // }, [isFaceSearchOpen]);

  const onPrefixImageClick = () => {
    setIsFaceSearchOpen(true);
  };

  const onPrefixImageClose = () => {
    setImage(null);
    onSearchFaceCancel();
  };

  const floatingFaceSearchRef = React.useRef<HTMLDivElement | null>(null); // Ref for FloatingFaceSearch

  const handleClickOutside = (event: MouseEvent) => {
    if (
      floatingFaceSearchRef.current && // Check ref is set
      !floatingFaceSearchRef.current.contains(event.target as Node) // Check if click was outside
    ) {
      setIsFaceSearchOpen(false);
    }
  };

  React.useEffect(() => {
    if (isFaceSearchOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFaceSearchOpen]);

  React.useEffect(() => {
    setFaceSearchEnabled?.(!!image);
  }, [image, setFaceSearchEnabled]);

  

  return (
    <div className={cn([styles.SearchBar, className])}>
      {!hideFilters && (
        <>
          {filterButton ? (
            image ? (
              <Tooltip className={styles.TooltipSlider} text="Not available for Face Search" moveTextOnHover={true}>
                <Button
                  icon={<SlidersHorizontal />}
                  mode="flat"
                  size="large"
                  onClick={onClickFiltersButton}
                  datatest="slider-button"
                  disabled={true}
                />
              </Tooltip>
            ) : (
            
              <Button
                icon={<SlidersHorizontal />}
                mode="flat"
                size="large"
                onClick={onClickFiltersButton}
                datatest="slider-button"
                disabled={false}
              />
          
            )
          ) : (
            ''
          )}
          <div className={styles.searchInput}>
            <Input
              placeholder={formatMessage(messages.search)}
              icon={searchButton || image ? undefined : <MagnifyingGlass />}
              value={searchTerm}
              onChange={handleSearch}
              data-has-search-button={searchButton}
              onKeyDown={handleKeyDown}
              datatest="search-bar-input"
              disabled={image ? true : false}
            />
            {searchFace ? (
              <>
                {image ? (
                  <div className={styles.inputImgHolder}>
                    <CloseCircle className={styles.inputImgCloseIcon} stroke="#FFFFFF" onClick={onPrefixImageClose} />
                    <img alt="search_image" className={styles.inputImgStyle} src={image} onClick={onPrefixImageClick} />
                  </div>
                ) : (
                  <Tooltip className={styles.faceIconTooltip} text="Face Search">
                    <FaceSearchIcon className={styles.faceIcon} onClick={toggleFaceSearch} />
                  </Tooltip>
                )}
                {isFaceSearchOpen && (
                  <div ref={floatingFaceSearchRef}>
                    <FloatingFaceSearch
                      image={image}
                      onClickBrowse={(imageData) => {
                        setImage(imageData);
                        onSearchFace(imageData);
                      }}
                      onClickCamera={(photo) => {
                        setImage(photo as string);
                        onSearchFace(photo as string);
                      }}
                      onClickCancel={() => {
                        setImage(null);
                        onSearchFaceCancel();
                      }}
                      foldableFaceFilter={foldableFaceFilter}
                    />
                  </div>
                )}
              </>
            ) : (
              <Tooltip className={styles.faceIconTooltip} text="Face Search">
                <FaceSearchIconDisabled className={styles.faceIcon} />
              </Tooltip>
            )}
            {searchButton && (
              <button onClick={handleClickSearchButton}>
                <MagnifyingGlass />
              </button>
            )}
          </div>
        </>
      )}

      {refreshButton ? (
        <Button
          className={styles.refetchButton}
          icon={<ArrowsClockwise />}
          onClick={handleRefresh}
          loading={isRefetching}
          mode="flat"
          tooltip={formatMessage(messages.refresh)}
        />
      ) : (
        ''
      )}

      {sortKeys ? (
        <Dropdown
          title="Sorting"
          className={styles.sortDropdown}
          isSortDropdown
          dropdownAnchor="right"
          sortDirection={sorting[1]}
          onClick={onSort}
          options={sortKeys.map((sortKey) => ({ ...sortKey, noBorderBottom: true }))}
          selectedKeys={[sorting[0]]}
          disabled={image ? true : false}
        >
          Sort by: {sortKeys.find(({ key }) => key === sorting[0])?.value}
        </Dropdown>
      ) : (
        ''
      )}
      {dropDownOptions ? (
        <Dropdown
          title="Filter Language"
          className={styles.sortDropdown}
          isSortDropdown
          dropdownAnchor="right"
          onClick={onClickDropdown}
          options={dropDownOptions}
          selectedKeys={[dropDownSelectedKeys]}
        >
          Filter by: {dropDownSelectedKeys}
        </Dropdown>
      ) : (
        ''
      )}
    </div>
  );
};

export default SearchBar;
