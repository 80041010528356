import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowLeftCircle } from 'src/common/icons';
import { Button, Input } from 'src/components';

import styles from '../Modal.module.scss';

import messages from '../messages';

type TAssetUpdatedConfirmationDialog = {
  onCreateNewAsset: (assetName: string, includeMetadata: boolean) => void;
  onReplaceOriginal: () => void;
  onCreateConversion: (conversionName: string) => void;
  onDownloadConversion: () => void;
  onCancel: () => void;
  assetType: 'original' | 'conversion';
};

export const AssetUpdatedDialog: React.FC<TAssetUpdatedConfirmationDialog> = ({
  onCreateNewAsset,
  onCreateConversion,
  onReplaceOriginal,
  onDownloadConversion,
  onCancel,
  assetType,
}) => {
  const [showNewAssetOptions, setShowNewAssetOptions] = useState(false);
  const [showConversionOptions, setShowConversionOptions] = useState(false);
  const [showReplaceOptions, setShowReplaceOptions] = useState(false);
  const [saveMetadata, setSaveMetadata] = useState(false);
  const [conversionName, setConversionName] = useState('');
  const [assetName, setAssetName] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleCreateConversion = () => {
    if (!conversionName) {
      setError('Conversion name is required');
      return;
    }

    if (conversionName.length > 20) {
      setError('Conversion name must be 20 characters or less');
      return;
    }

    if (!/^[a-zA-Z0-9_-]{1,}$/.test(conversionName)) {
      setError('Conversion name must be alphanumeric and can contain "-" and "_"');
      return;
    }

    onCreateConversion(conversionName);
    setLoading(true);
  };

  const handleAddAsset = () => {
    if (!assetName) {
      setError('Conversion name is required');
      return;
    }

    if (assetName.length > 20) {
      setError('Conversion name must be 20 characters or less');
      return;
    }

    if (!/^[a-zA-Z0-9_-]{1,}$/.test(assetName)) {
      setError('Conversion name must be alphanumeric and can contain "-" and "_"');
      return;
    }

    onCreateNewAsset(assetName, saveMetadata);
    setLoading(true);
  };

  const handleDownloadConversion = () => {
    setLoading(true);
    onDownloadConversion();
  };
  const handleReplaceOriginal = () => {
    if (assetType === 'original') {
      setShowReplaceOptions(true);
    } else {
      setLoading(true);
      onReplaceOriginal();
    }
  };
  const handleReplaceOriginalImage = () => {
    setLoading(true);
    onReplaceOriginal();
  };
  return (
    <div className={styles.AssetUpdatedConfirmationDialogContainer}>
      <div className={styles.AssetUpdatedConfirmationDialog}>
        {showConversionOptions || showReplaceOptions || showNewAssetOptions ? (
          <>
            {showConversionOptions && (
              <>
                <Button
                  icon={<ArrowLeftCircle />}
                  mode="flat"
                  size="small"
                  onClick={() => setShowConversionOptions(false)}
                >
                  <FormattedMessage {...messages.goBack} />
                </Button>
                <div className={styles.conversionForm}>
                  <Input
                    type="text"
                    variant="secondary"
                    value={conversionName}
                    onChange={(value) => setConversionName(value)}
                    placeholder="Type a name for the conversion"
                    label="Conversion Name"
                    className={styles.conversionFormInput}
                    error={error}
                  />

                  <Button type="button" mode="primary" size="small" onClick={handleCreateConversion} loading={loading}>
                    <FormattedMessage {...messages.saveConversion} />
                  </Button>
                </div>
              </>
            )}
            {showNewAssetOptions && (
              <>
                <Button
                  icon={<ArrowLeftCircle />}
                  mode="flat"
                  size="small"
                  onClick={() => setShowNewAssetOptions(false)}
                >
                  <FormattedMessage {...messages.goBack} />
                </Button>
                <div className={styles.conversionForm}>
                  <Input
                    type="text"
                    variant="secondary"
                    value={assetName}
                    onChange={(value) => setAssetName(value)}
                    placeholder="Type a name for the asset"
                    label="Asset Name"
                    className={styles.conversionFormInput}
                    error={error}
                  />
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingTop: '15px' }}>
                    <input
                      type="checkbox"
                      checked={saveMetadata}
                      onChange={(e) => setSaveMetadata(e.target.checked)}
                      className={styles.metadataCheckbox}
                    />
                    Include Metadata
                  </div>

                  <Button type="button" mode="primary" size="small" onClick={handleAddAsset} loading={loading}>
                    <FormattedMessage {...messages.saveNewAsset} />
                  </Button>
                </div>
              </>
            )}
            {showReplaceOptions && (
              <>
                <Button
                  icon={<ArrowLeftCircle />}
                  mode="flat"
                  size="small"
                  onClick={() => setShowReplaceOptions(false)}
                >
                  <FormattedMessage {...messages.goBack} />
                </Button>
                <div className={styles.conversionForm}>
                  <div style={{ color: 'blue' }}>
                    <FormattedMessage {...messages.replaceOriginalImageNote} />
                  </div>

                  <Button
                    type="button"
                    mode="primary"
                    size="small"
                    onClick={handleReplaceOriginalImage}
                    loading={loading}
                  >
                    <FormattedMessage {...messages.replaceOriginalImage} values={{ assetType: 'Image' }} />
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <h3>
              <FormattedMessage {...messages.assetUpdateDialogTitle} />
            </h3>
            <div className={styles.AssetDialogButtons}>
              <Button type="button" mode="primary" size="small" onClick={() => setShowNewAssetOptions(true)}>
                <FormattedMessage {...messages.addAsNewAsset} />
              </Button>
              {/* <Button
                type="button"
                mode="decline"
                size="small"
                onClick={() => {
                  handleReplaceOriginal();
                }}
              >
                <FormattedMessage
                  {...messages.replaceOriginalImage}
                  values={{ assetType: assetType === 'original' ? 'Image' : 'Conversion' }}
                />
              </Button> */}

              <Button type="button" mode="primary" size="small" onClick={() => setShowConversionOptions(true)}>
                <FormattedMessage {...messages.addAsAConversion} />
              </Button>

              <Button type="button" mode="tertiary" size="small" onClick={handleDownloadConversion} loading={loading}>
                <FormattedMessage {...messages.downloadEditedCopy} />
              </Button>

              <Button icon={<ArrowLeftCircle />} mode="flat" size="small" onClick={onCancel}>
                <FormattedMessage {...messages.cancel} />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
