export enum PAGE_ACTIONS {
  ADD_TO_COLLECTIONS = 'ADD_TO_COLLECTIONS',
  CREATE_COLLECTION = 'CREATE_COLLECTION',
  ADD_TO_BASKET = 'ADD_TO_BASKET',
  MOVE_TO_TRASH = 'MOVE_TO_TRASH',
}

export const ITEMS_PER_PAGE_LOCAL_KEY = 'fs.assets.itemsPerPage';

export const INITIAL_FILTERS = {
  page: 1,
  hash: '',
  face: null,
  limit_per_page: 30,
  keyword: '',
  extended_result: true,
  sort_by: ['upload_time_range_DESC'],
  content_type: '',
  file_name: '',
  trashed: false,
  upload_time_range: {
    start: undefined,
    end: undefined,
  },
  status: '',
  custom_content_input:'',
  // schema_criteria:{
  //   data_schema_id:undefined
  // }
  filters:null
};
