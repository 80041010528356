import { config } from 'src/utils/config';
import { FetchResult } from 'src/types/TApiQueries';
import { getStandardHeaders } from 'src/api/utils/helpers';

export async function getRBG(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const bgRemoveUrl = `${config.hostUrl}/api/v1/assets/${assetId}/addons/bg_remove`;
  const header = getStandardHeaders(accessToken);
  try {
    const response = await fetch(bgRemoveUrl, { headers: header });
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    const blob = await response.blob();
    const reader = new FileReader();

    const base64String = await new Promise<string>((resolve, reject) => {
      reader.onloadend = () => {
        const result = reader.result as string; // Type assertion to string
        if (result) {
          resolve(result.split(',')[1]);
        } else {
          reject(new Error('FileReader result is null'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    return base64String;
  } catch (err) {
    console.log('Error:', err);
    return null;
  }
}
