import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { useEffect, useState, useMemo, useRef } from 'react';
import {
  SearchBar,
  ListingCard,
  ListingRow,
  Input,
  PageTitle,
  Tag,
  Pager,
  Button,
  Checkbox,
  BatchActions,
  LayoutViewSwitcher,
  LayoutView,
  AddAssetsToCollections,
  Select,
  Dropdown,
  InfoSection,
} from 'src/components';
import { useSearchQuery } from 'src/api/queries/assetsQueries';
import { getThumbnailUrl } from 'src/utils/previews';
import { CaretRight, CaretDown, FolderPlus, Stack, Trash } from 'src/common/icons';
import styles from './styles.module.scss';
import messages from './messages';
import useFileSpinPicker from 'src/hooks/useFileSpinPicker';
import { lockScroll, unlockScroll } from 'src/utils/bodyScroll';
import { useCollectionMutation } from 'src/api/mutations/collectionMutations';
import { queryNames } from 'src/api/utils/queryNames';
import { CollectionResponse } from 'src/types/TCollection';
import { deleteAsset } from 'src/api/functions/assetsApi';
import CreateCollectionModal from 'src/components/CreateCollectionModal';
import { useCachePreviewImages } from './hooks';
import useComponentHooks from './hooks';
import { ITEMS_PER_PAGE_LOCAL_KEY, PAGE_ACTIONS } from './constants';
import { statusOptions } from 'src/api/queries/assetsQueries';
import { getSchemas } from 'src/api/functions/schemaApi';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { ISubmitEvent } from '@rjsf/core';
import SearchForm from 'src/components/SearchForm';
import { useSettingsQuery } from 'src/api/queries/userQueries';
import { useIsAuthenticated } from 'react-auth-kit';
import SetupFreeFormSearchData from './SetupFreeFormSearch';

/**
 *  @name Dashboard
 *  @module pages/Dashboard
 *
 *  @route GET /
 *  @route GET /assets
 *
 *  @description The Dashboard page.
 *
 *  @summary
 *  This page is the main page of the application. It displays the assets of the user.
 *  It is possible to filter the assets by using the search bar, the filters and the sorting.
 *  It is also possible to add assets to a collection, delete assets and create a new collection.
 *  The assets are displayed in a list or in a grid. The user can switch between the two views.
 *  The user can also select assets to perform batch actions on them.
 *  The user can also navigate between the pages of the assets.
 *
 *  @param {void}
 *
 *  @returns {JSX.Element} The Dashboard page as a JSX.Element.
 *
 */
export const Dashboard = (): JSX.Element => {
  const hooks = useComponentHooks();
  const { filters, dispatchFilter } = hooks.reducer;
  const accessToken = useAccessToken();
  const isAuthenticated = useIsAuthenticated();
  const { data: settingsData } = useSettingsQuery(undefined, {
    enabled: isAuthenticated(),
  });

  const {
    data: fetchedData,
    isLoading,
    refetch,
    isRefetching,
  } = useSearchQuery(filters, {
    onSuccess: (results) => {
      hooks.state.totalPages !== results.total_pages && hooks.state.setTotalPages(results.total_pages);
      hooks.refs.searchHash.current = results.search_result_id;
    },
  });

  const { formatMessage } = useIntl();
  const [schemaSearchMode, setSchemaSearchMode] = useState(false);
  const [schemaFaceSearchMode, setFaceSchemaSearchMode] = useState(false);
  const [schema, setSchema] = useState<any>();
  const [faceSchema, setFaceSchema] = useState<any>();
  const [selectedSchemaId, setSelectedSchemaId] = useState<any>('-1');
  const [selectedSchemaIdFace, setSelectedSchemaIdFace] = useState<any>('-1');
  const [selectSchemaOptions, setSelectSchemaOptions] = useState<any>([]);
  const [selectSchemaOptionsFace, setSelectSchemaOptionsFace] = useState<any>([]);
  const [schemaIdDataMapping, setSchemaIdDataMapping] = useState<any>({});
  const [schemaIdDataMappingFace, setSchemaIdDataMappingFace] = useState<any>({});
  const [schemaKeyValueMapping, setSchemaKeyValueMapping] = useState<any>({});
  const [schemaKeyValueMappingFace, setSchemaKeyValueMappingFace] = useState<any>({});
  const [metadata, setMetaData] = useState<any>({});
  const [searchMetaData, setSearchMetaData] = useState<any>({});

  const sortByKeyAscending = (options: Array<{ key: string | number; value: string }>) => {
    return options.sort((a, b) => Number(a.key) - Number(b.key));
  };

  const fetchSchemas = async () => {
    const result = await getSchemas(accessToken);
    if (!result.data) return;
    const keyValueMapping: any = {};
    const idSchemaMapping: any = {};
    let options = [
      { value: 'None Selected', key: '-1' },
      ...result.data.map((schema: any) => {
        keyValueMapping[schema.id.toString()] = schema.name.en;
        idSchemaMapping[schema.id] = schema;
        return {
          value: `${schema.name.en} (ID: ${schema.id.toString()})`,
          key: schema.id.toString(),
        };
      }),
    ];

    options = sortByKeyAscending(options)

    setSchemaKeyValueMapping(keyValueMapping);
    setSchemaIdDataMapping(idSchemaMapping);
    setSelectSchemaOptions(options);
    setSchemaKeyValueMappingFace(keyValueMapping);
    setSchemaIdDataMappingFace(idSchemaMapping);
    setSelectSchemaOptionsFace(options);
  };

  useEffect(() => {
    fetchSchemas();
  }, []);

  useEffect(() => {
    if (hooks.queries.collections.status === 'success' && hooks.queries.collections.data?.status === 'OK') {
      localStorage.setItem('assetCollection', JSON.stringify(hooks.queries.collections.data));
    }
  }, [hooks.queryClient, hooks.queries]);

  const basketMutation = useCollectionMutation(Number(hooks.queries.collections.data?.basketData?.data?.id), {
    onError: (error, newData, context: any) => {
      hooks.queryClient.setQueryData([queryNames.collection, null], context.previousData);

      hooks.alerts.showAlert(hooks.errorMessages.getErrorMessage(error.message));
    },
    onMutate: (data) => {
      const previousData = hooks.queryClient.getQueryData<CollectionResponse>([queryNames.collection, null]);
      hooks.queryClient.setQueryData<CollectionResponse | undefined>(
        [queryNames.collection, null],
        (old: CollectionResponse | undefined) => {
          if (typeof old !== 'undefined') {
            old.data.assets = (old?.data?.assets ?? [])
              .filter((id) => !(data?.deletions ?? []).includes(id))
              .concat(data?.additions ?? []);
          }
          return old;
        },
      );
      return { previousData };
    },
    onSuccess: () => {
      hooks.alerts.showSuccess('Request succeeded. Refreshing data...');
    },
  });

  const { uploadContent } = useFileSpinPicker({
    events: {
      complete: (d) => {
        console.log(data, d);
        // TODO: Implement a global search & pagination state handler
        refetch();
      },
      close: () => unlockScroll(),
      open: () => lockScroll(),
    },
  });

  useEffect(() => {
    hooks.state.updateDeletedAssets([]);
  }, []);

  const data = useMemo(() => {
    const result = fetchedData?.result.filter((asset: string) => !hooks.state.deletedAssets.includes(asset));
    return { ...fetchedData, result };
  }, [fetchedData, hooks.state.deletedAssets]);

  useCachePreviewImages(data);

  const handleAddAsset = () => {
    uploadContent();
  };

  const handleAddSelectedAssetsToBasket = () => {
    if (basketMutation.isLoading) return;
    basketMutation.mutateAsync({ additions: hooks.state.selectedAssets });
    hooks.state.updateSelectedAssets([]);
  };

  useEffect(() => {
    localStorage.setItem(ITEMS_PER_PAGE_LOCAL_KEY, String(hooks.reducer.filters.limit_per_page));
  }, [hooks.reducer.filters.limit_per_page]);

  useEffect(() => {
    hooks.reducer.dispatchFilter({ sort_by: [hooks.state.sorting.join('_')] });
  }, [hooks.state.sorting]);

  useEffect(() => {
    hooks.reducer.dispatchFilter({ keyword: hooks.searchDebounced, page: 1, hash: '' });
  }, [hooks.searchDebounced]);

  useEffect(() => {
    hooks.reducer.dispatchFilter({ file_name: hooks.fileNameDebounced, page: 1, hash: '' });
  }, [hooks.fileNameDebounced]);

  useEffect(() => {
    if (hooks.customContentDebounced !== '') {
      hooks.reducer.dispatchFilter({ custom_content_input: hooks.customContentDebounced, page: 1, hash: '' });
    } else {
      hooks.reducer.dispatchFilter({ custom_content_input: '', page: 1, hash: '' })
    }
  }, [hooks.customContentDebounced]);
  // useEffect(()=>{
  //   hooks.reducer.dispatchFilter({schema_criteria:hooks.schemCriteriaDebounced,page:1,hash:''});
  // },[hooks.schemCriteriaDebounced])

  // needs bridge Layer for filters change (with debounce?), commit each change with hash -> '' and page -> 1

  useEffect(() => {
    if (isRefetching) return;
    refetch();
  }, [hooks.state.refreshValue]);

  useEffect(() => {
    hooks.state.setIsRefetching(isRefetching);
  }, [isRefetching]);

  const handleOnAssetSelectChange = (assetId: string) => {
    const newSelectedAssets = hooks.state.selectedAssets.includes(assetId)
      ? hooks.state.selectedAssets.filter((id) => id !== assetId)
      : [...hooks.state.selectedAssets, assetId];
    hooks.state.updateSelectedAssets(newSelectedAssets);
  };

  const handleSelectAllAssets = (selected: boolean) => {
    const newSelectedAssets = selected ? data.result : [];
    hooks.state.updateSelectedAssets(newSelectedAssets);
  };

  const handleMoveToTrash = async () => {
    if (hooks.state.isDeletingAssets) return;

    hooks.state.setIsDeletingAssets(true);

    try {
      const response: any = await Promise.all(
        hooks.state.selectedAssets.map((assetId) => deleteAsset(assetId, hooks.accessToken)),
      );

      if (typeof response.status === 'number' && ![200, 202].includes(response.status)) {
        throw response;
      }

      const newDeletedAssets = [...hooks.state.deletedAssets, ...hooks.state.selectedAssets];

      hooks.state.updateDeletedAssets(newDeletedAssets);
      hooks.alerts.showSuccess(`Successfully deleted selected assets`);
    } catch (error: any) {
      const errorMessage = hooks.errorMessages.getErrorMessage(error.message);
      hooks.alerts.showAlert(errorMessage);
    } finally {
      hooks.state.setIsDeletingAssets(false);
      hooks.state.updateSelectedAssets([]);
    }
  };

  useEffect(() => {
    return () => {
      console.log('clearing selected assets');
      hooks.state.updateSelectedAssets([]);
    };
  }, []);

  const handleOnClickAction = (option: string) => {
    switch (option) {
      case PAGE_ACTIONS.ADD_TO_COLLECTIONS:
        const addAssetsToCollectionsElement = <AddAssetsToCollections />;
        return hooks.modal.showModal(addAssetsToCollectionsElement);
      case PAGE_ACTIONS.ADD_TO_BASKET:
        return handleAddSelectedAssetsToBasket();
      case PAGE_ACTIONS.MOVE_TO_TRASH:
        return handleMoveToTrash();
      case PAGE_ACTIONS.CREATE_COLLECTION:
        const routeToCollections = () => hooks.history.push('/collections');
        const element = <CreateCollectionModal onCreateCollection={routeToCollections} />;
        return hooks.modal.showModal(element);
    }
  };

  const uploadDateRange = useMemo(() => {
    return {
      start: hooks.reducer.filters.upload_time_range.start,
      end: hooks.reducer.filters.upload_time_range.end,
    };
  }, [hooks.reducer.filters.upload_time_range.start, hooks.reducer.filters.upload_time_range.end]);

  useEffect(() => {
    if (uploadDateRange.start || uploadDateRange.end) {
      hooks.reducer.dispatchFilter({ page: 1, hash: '' });
    }
  }, [uploadDateRange]);
  // useEffect(() => {
  //   const { start, end } = hooks.reducer.filters.upload_time_range;
  //   if (start || end) {
  //     hooks.reducer.dispatchFilter({ page: 1, hash: '' });
  //   }
  // }, [hooks.reducer.filters.upload_time_range.start, hooks.reducer.filters.upload_time_range.end]);

  const pageActions = useMemo(() => {
    const actions = [
      {
        key: PAGE_ACTIONS.ADD_TO_COLLECTIONS,
        value: 'Add to Collections',
        noBorderBottom: false,
        icon: <FolderPlus />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      },
      {
        key: PAGE_ACTIONS.CREATE_COLLECTION,
        value: 'Create new Collection',
        noBorderBottom: false,
        icon: <Trash />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      },
      {
        key: PAGE_ACTIONS.ADD_TO_BASKET,
        value: 'Add to Basket',
        noBorderBottom: false,
        icon: <Stack />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      },
    ];

    if (!hooks.roleManager.isUser) {
      actions.push({
        key: PAGE_ACTIONS.MOVE_TO_TRASH,
        value: 'Move to Trash',
        noBorderBottom: false,
        icon: <Trash />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      });
    }

    return actions;
  }, [hooks.roleManager.isUser, hooks.state.selectedAssets]);

  const handleFilenameChange = (value: string) => hooks.state.setFileName(value);

  const [showFreeFormData, setShowFreeFormData] = useState<boolean>(false);
  const [showFreeFormFields, setShowFreeFormFields] = useState<boolean>(false);

  type RenderSearchFormProps = {
    schema?: any;
    metadata: any;
    onSubmit: ((e: ISubmitEvent<any>, nativeEvent: React.FormEvent<HTMLFormElement>) => any) | undefined;
    onCancel: () => void;
  };

  const handleSubmit = () => {
    document.getElementById('hiddenSubmitButton')?.click();
  };

  const RenderSearchForm: React.FC<RenderSearchFormProps> = ({ schema, metadata, onSubmit, onCancel }) => {
    return (
      <>
        {schemaSearchMode && (
          <div ref={formRef} className={cn(styles.formContainer, { [styles.expanded]: schemaSearchMode })}>
            <SearchForm schema={schema} metadata={metadata} onSubmit={onSubmit} onCancel={onCancel} />
          </div>
        )}
        <div className={styles.formButtonsContainer}>
          <Button size="small" mode="tertiary" type="button" onClick={handleCancel} disabled={!schemaSearchMode}>
            Cancel
          </Button>
          <Button size="small" mode="primary" type="button" onClick={handleSubmit} disabled={!schemaSearchMode}>
            Submit
          </Button>
        </div>
      </>
    );
  };

  const RenderFaceSearchForm: React.FC<RenderSearchFormProps> = ({ schema, metadata, onSubmit, onCancel }) => {
    return (
      <>
        {!showFreeFormData && schemaFaceSearchMode && (
          <>
            <div ref={formRef} className={cn(styles.formFaceContainer, { [styles.expanded]: schemaFaceSearchMode })}>
              <SearchForm schema={schema} metadata={metadata} onSubmit={onSubmit} onCancel={onCancel} />
            </div>
            <div className={styles.formFaceButtonsContainer}>
              <Button
                size="small"
                mode="tertiary"
                type="button"
                onClick={handleCancel}
                disabled={!schemaFaceSearchMode}
              >
                Cancel
              </Button>
              <Button size="small" mode="primary" type="button" onClick={handleSubmit} disabled={!schemaFaceSearchMode}>
                Submit
              </Button>
            </div>
          </>
        )}
      </>
    );
  };

  const renderDropdownValue = (value: string) => {
    if (!value || value.length <= 28) {
      return value;
    }
    return `${value.slice(0, 25)}...`;
  };

  const processOptions = (data: any) => {
    const updatedData: any = {};

    for (const key in data) {
      const value = data[key];
      if (
        value === undefined ||
        value === '' ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && value !== null && Object.keys(value).length === 0) || value === false
      ) {
        continue;
      }


      updatedData[key] = typeof value === 'object' ? JSON.stringify(value) : value;
    }

    return updatedData;
  }


  const submitSearchData = async (schemaObject: any) => {
    const options = processOptions(schemaObject?.formData);
    setMetaData(schemaObject?.formData);
    setSearchMetaData(schemaObject?.formData);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (hooks.reducer.filters.face) {
      const newFilters = {
        ...filters,
        hash: '',
        page: 1,
        filters: options,
      };
      dispatchFilter(newFilters);
    } else {
      const newFilters = {
        ...filters,
        page: 1,
        hash: '',
        schema_criteria: {
          data_schema_id: Number(selectedSchemaId),
          fields: options,
        },
        extended_result: true,
      };
      dispatchFilter(newFilters);
    }
  };

  const formRef = useRef<any>(null);

  useEffect(() => {
    if (schemaSearchMode && formRef.current) {
      const height = formRef.current.scrollHeight > 400 ? 400 : formRef.current.scrollHeight;
      formRef.current.style.maxHeight = `${height}px`;
    }
  }, [schemaSearchMode]);

  const handleCancel = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    hooks.enhancedDispatchFilter(filters);
    setSchemaSearchMode(false);
    setSelectedSchemaId('-1');
    setSelectedSchemaIdFace('-1');
    setMetaData({});
    setSearchMetaData({});
    setShowFreeFormFields(false);
  };

  const onFaceSearchClose = () => {
    console.log('face search cancel');
    hooks.state.setFace(null);
    hooks.state.setSearch('');
    hooks.enhancedFaceRemoval(filters);
    setSchemaSearchMode(false);
    setSelectedSchemaId('-1');
    setSelectedSchemaIdFace('-1');
    setMetaData({});
    setSearchMetaData({});
  };

  const isFreeFormData = useMemo(() => selectedSchemaIdFace === '-1', [selectedSchemaIdFace]);

  useEffect(() => {
    setShowFreeFormData(isFreeFormData);
  }, [isFreeFormData]);

  const handleFreeFormSubmit = (fieldsData: Record<string, string>) => {
    const newoptions = processOptions(fieldsData);
    const newFilters = {
      ...filters,
      hash: '',
      page: 1,
      filters: newoptions,
    };

    dispatchFilter(newFilters);
  };

  const handleCancelFreeFormData = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    hooks.enhancedDispatchFilter(filters);
    setSchemaSearchMode(false);
    setSelectedSchemaId('-1');
    setSelectedSchemaIdFace('-1');
    setMetaData({});
    setSearchMetaData({});
    setShowFreeFormFields(false);
  };


  const foldableFilterComponent = (
    <div style={{ minHeight: "400px", minWidth: "400px" }}>

      {hooks.reducer.filters.face ? (
        <>
          <FoldableFilter title={'Filters'}>
            <div>
              <Dropdown
                className={styles.dropD}
                isMultiple={false}
                options={selectSchemaOptionsFace}
                onClick={(key) => {
                  if (key === '-1') {
                    handleCancel();
                  } else {
                    setSelectedSchemaIdFace(key);
                    setFaceSchema(schemaIdDataMappingFace[Number(key)]?.schema);
                    setFaceSchemaSearchMode(true); // Ensuring it sets to true when an option is selected
                    setSearchMetaData({});
                  }
                }}
                reduceOptionSize={true}
              >
                {selectSchemaOptionsFace?.length > 0 ? (
                  selectedSchemaIdFace === '-1' ? (
                    'None Selected'
                  ) : (
                    `${renderDropdownValue(schemaKeyValueMappingFace[selectedSchemaIdFace])} (ID: ${selectedSchemaIdFace})`
                  )
                ) : (
                  <br />
                )}
              </Dropdown>
            </div>
            {schemaFaceSearchMode && !showFreeFormData && (
              <div className={cn(styles.formFaceNewContainer, { [styles.expanded]: schemaFaceSearchMode })}>
                <RenderFaceSearchForm
                  schema={faceSchema}
                  metadata={searchMetaData}
                  onSubmit={submitSearchData}
                  onCancel={handleCancel}
                />
              </div>
            )}
            {/* <div style={{ marginTop: '1.5rem', maxWidth: "330px" }}>
                        {showFreeFormData && (
                            <div className={styles.metadataInitContainer}>
                                <div className={styles.metadataInitForm}>
                                    <Button mode="primary" size="small" onClick={() => setShowFreeFormFields(true)}>
                                        Add Free Form Data
                                    </Button>
                                </div>
                                {showFreeFormFields && (
                                    <SetupFreeFormSearchData
                                        onSubmit={handleFreeFormSubmit}
                                        onFieldCancel={() => setShowFreeFormFields(false)}
                                        onCancel={handleCancelFreeFormData}
                                    />
                                )}
                            </div>
                        )}
                    </div> */}
          </FoldableFilter>
        </>
      ) : (
        <div className={styles.foldableContainer} >
          <h4 >
            {<CaretRight />} {"Filters"}
          </h4>
          <div style={{ maxWidth: "330px" }}><InfoSection text={formatMessage(messages.searchInfo)} /></div>
        </div>
      )}

    </div>
  );



  const [isFaceSearchEnabled, setIsFaceSearchEnabled] = useState<boolean>(false)
  const handleContentTypeInputChange = (value: string) => hooks.state.setCustomContentInput(value);


  return (
    <div className={styles.pageWrapper} data-test="page-content">
      <div>
        <PageTitle
          title={'Assets'}
          className={styles.pageTitle}
          counter={data?.total_files}
          rightContainer={
            hooks.roleManager.isUser ? null : (
              <Button size="small" onClick={handleAddAsset}>
                <FormattedMessage {...messages.add} />
              </Button>
            )
          }
        />
        <SearchBar
          search={hooks.state.search}
          sortKeys={[
            { key: 'upload_time_range', value: 'Upload Time' },
            { key: 'file_name', value: 'File Name' },
            { key: 'content_type', value: 'Content Type' },
            { key: 'file_size_range', value: 'File Size' },
          ]}
          onSelectSort={(sortKey) => hooks.state.setSorting(sortKey)}
          onSearch={(searchTerm) => hooks.state.setSearch(searchTerm)}
          onSearchFace={(img) => {
            hooks.state.setFace(img);

            hooks.enhancedFaceSearch(img, filters);
          }}
          onSearchFaceCancel={onFaceSearchClose}
          onClickFiltersButton={() => hooks.state.setFiltersVisible(!hooks.state.filtersVisible)}
          searchFace={settingsData?.addons?.FACE_RECOGNITION?.added && settingsData?.addons?.FACE_RECOGNITION?.enabled}
          foldableFaceFilter={foldableFilterComponent}
          faceSearchEnabled={isFaceSearchEnabled}
          setFaceSearchEnabled={setIsFaceSearchEnabled}
        />
        <section className={styles.pageContainer}>
          {hooks.state.filtersVisible && !isFaceSearchEnabled && (
            <div className={cn([styles.filtersContent])}>
              <FoldableFilter title={'File Name'}>
                <Input value={hooks.state.fileName} onChange={handleFilenameChange} />
              </FoldableFilter>
              <FoldableFilter title={'Content Type'} dataTest="filter-click">
                <Checkbox
                  label="All"
                  checked={hooks.reducer.filters.content_type === ''}
                  onChange={() => {
                    hooks.reducer.dispatchFilter({ content_type: '', page: 1, hash: '', custom_content_input: '' });
                    hooks.state.setCustomContentInput('');
                  }}
                  radio={true}
                />
                <Checkbox
                  label="Image"
                  checked={hooks.reducer.filters.content_type === 'image'}
                  onChange={() => {
                    const isSelected = hooks.reducer.filters.content_type === 'image';
                    hooks.reducer.dispatchFilter({
                      content_type: isSelected ? '' : 'image',
                      page: 1,
                      hash: '',
                      custom_content_input: '',
                    });
                    hooks.state.setCustomContentInput('');
                  }}
                  radio={true}
                  datatest='image-click'
                />

                <Checkbox
                  label="Video"
                  checked={hooks.reducer.filters.content_type === 'video'}
                  onChange={() => {
                    const isSelected = hooks.reducer.filters.content_type === 'video';
                    hooks.reducer.dispatchFilter({
                      content_type: isSelected ? '' : 'video',
                      page: 1,
                      hash: '',
                      custom_content_input: '',
                    });
                    hooks.state.setCustomContentInput('');
                  }}
                  radio={true}
                />

                <Checkbox
                  label="Audio"
                  checked={hooks.reducer.filters.content_type === 'audio'}
                  onChange={() => {
                    const isSelected = hooks.reducer.filters.content_type === 'audio';
                    hooks.reducer.dispatchFilter({
                      content_type: isSelected ? '' : 'audio',
                      page: 1,
                      hash: '',
                      custom_content_input: '',
                    });
                    hooks.state.setCustomContentInput('');
                  }}
                  radio={true}
                />
                <div>
                  <Checkbox
                    label="Custom"
                    checked={
                      hooks.reducer.filters.content_type !== '' &&
                      !['image', 'video', 'audio'].includes(hooks.reducer.filters.content_type)
                    }
                    onChange={() => {
                      const isSelected =
                        hooks.reducer.filters.content_type !== '' &&
                        !['image', 'video', 'audio'].includes(hooks.reducer.filters.content_type);
                      hooks.reducer.dispatchFilter({
                        content_type: isSelected ? '' : '*',
                        page: 1,
                        hash: '',
                        custom_content_input: '',
                      });
                      hooks.state.setCustomContentInput('');
                    }}
                    radio={true}
                  />

                  <div style={{ marginTop: '1rem', maxWidth: '290px', marginLeft: '2.3rem' }}>
                    <Input
                      placeholder="Eg: application/pdf"
                      value={hooks.state.customContentInput}
                      onChange={handleContentTypeInputChange}
                      disabled={
                        hooks.reducer.filters.content_type === '' ||
                        hooks.reducer.filters.content_type === 'image' ||
                        hooks.reducer.filters.content_type === 'video' ||
                        hooks.reducer.filters.content_type === 'audio'
                      }
                    />
                  </div>
                </div>
              </FoldableFilter>
              <FoldableFilter title={'Upload Date Range'}>
                <div className={styles.rangeValues}>
                  <Input
                    type="date"
                    label="Start Date :"
                    value={hooks.startDateInputValue}
                    onChange={hooks.handleStartDateChange}
                  />
                  <Input
                    type="date"
                    label="End Date :"
                    value={hooks.endDateInputValue}
                    onChange={hooks.handleEndDateChange}
                  />
                </div>
                {/* TODO: 1. DOCUMENT DATES FILTER, 2. Dates manipulations needs proper abstraction */}
              </FoldableFilter>
              <FoldableFilter title={'Status'}>
                <Select
                  className={styles.Select}
                  options={statusOptions}
                  value={hooks.reducer.filters.status}
                  onChange={(v) => {
                    hooks.reducer.dispatchFilter({
                      status: v,
                    });
                  }}
                />
              </FoldableFilter>
              <FoldableFilter title={'Schema'}>
                <div>
                  <Dropdown
                    className={styles.dropD}
                    isMultiple={false}
                    options={selectSchemaOptions}
                    onClick={(key) => {
                      if (key === '-1') {
                        handleCancel();
                      } else {
                        setSelectedSchemaId(key);
                        setSchema(schemaIdDataMapping[Number(key)]?.schema);
                        setSchemaSearchMode(true);
                        setMetaData({});
                      }
                    }}
                  >
                    {selectSchemaOptions?.length > 0 ? (
                      selectedSchemaId === '-1' ? (
                        'None Selected'
                      ) : (
                        `${renderDropdownValue(schemaKeyValueMapping[selectedSchemaId])} (ID: ${selectedSchemaId})`
                      )
                    ) : (
                      <br />
                    )}
                  </Dropdown>
                  <div className={cn(styles.formNewContainer, { [styles.expanded]: schemaSearchMode })}>
                    <RenderSearchForm
                      schema={schema}
                      metadata={metadata}
                      onSubmit={submitSearchData}
                      onCancel={() => {
                        handleCancel();

                        // setSelectedSchemaId(asset.data_schema_id)
                        // setSchema(schemaIdDataMapping[asset?.data_schema_id]?.schema)
                      }}
                    />
                  </div>
                </div>
              </FoldableFilter>

              {/* )} */}
            </div>
          )}
          <div className={cn([styles.pageContent])}>
            <div className={styles.pageActions}>
              <BatchActions
                showSelectAll={data?.result?.length > 0}
                onSelectAll={handleSelectAllAssets}
                pageActions={{
                  options: pageActions,
                  onClick: handleOnClickAction,
                }}
              />
              <LayoutViewSwitcher />
            </div>

            <div className={styles.filtersWrapper}>
              <div className={styles.tags}>
                {hooks.state.search !== '' && (
                  <Tag text={hooks.state.search} onClose={() => hooks.state.setSearch('')} />
                )}
                {hooks.reducer.filters.file_name !== '' && (
                  <Tag
                    text={`File: ${hooks.reducer.filters.file_name}`}
                    onClose={() => {
                      hooks.reducer.dispatchFilter({ file_name: '', page: 1, hash: '' });
                      hooks.state.setFileName('');
                    }}
                  />
                )}
                {hooks.reducer.filters.content_type !== '' &&
                  hooks.reducer.filters.content_type !== '*' &&
                  hooks.reducer.filters.custom_content_input === '' && (
                    <Tag
                      text={`Content Type: ${hooks.reducer.filters.content_type}`}
                      onClose={() => {
                        hooks.reducer.dispatchFilter({ content_type: '', page: 1, hash: '' });
                        hooks.state.setCustomContentInput('');
                      }}
                    />
                  )}

                {hooks.reducer.filters.custom_content_input !== '' && (
                  <Tag
                    text={`Content Type: ${hooks.reducer.filters.custom_content_input}`}
                    onClose={() => {
                      hooks.reducer.dispatchFilter({ content_type: '*', page: 1, hash: '', custom_content_input: '' });
                      hooks.state.setCustomContentInput('');
                    }}
                  />
                )}

                {(hooks.reducer.filters.upload_time_range.start || hooks.reducer.filters.upload_time_range.end) && (
                  <Tag
                    text={
                      hooks.reducer.filters.upload_time_range.start && hooks.reducer.filters.upload_time_range.end
                        ? `Range: ${hooks.reducer.filters.upload_time_range.start?.substring(
                          0,
                          10,
                        )} to ${hooks.reducer.filters.upload_time_range.end?.substring(0, 10)}`
                        : hooks.reducer.filters.upload_time_range.start
                          ? `Start: ${hooks.reducer.filters.upload_time_range.start?.substring(0, 10)}`
                          : `End: ${hooks.reducer.filters.upload_time_range.end?.substring(0, 10)}`
                    }
                    onClose={() => {
                      if (
                        hooks.reducer.filters.upload_time_range.start &&
                        hooks.reducer.filters.upload_time_range.end
                      ) {
                        hooks.clearRangeDate();
                      } else if (hooks.reducer.filters.upload_time_range.start) {
                        hooks.clearStartDate();
                      } else if (hooks.reducer.filters.upload_time_range.end) {
                        hooks.clearEndDate();
                      }
                    }}
                  />
                )}
                {hooks.reducer.filters.status !== '' && (
                  <Tag
                    text={`Status: ${hooks.reducer.filters.status}`}
                    onClose={() => {
                      hooks.reducer.dispatchFilter({ status: '', page: 1, hash: '' });
                    }}
                  />
                )}
                {/*<Button size="small" mode="flat">
              <FormattedMessage {...messages.saveSearch} />
            </Button>*/}
              </div>
            </div>

            <LayoutView
              data={data?.result}
              emptyDataMessage="No assets found"
              isLoading={isLoading || isRefetching}
              classNames={{
                cardView: 'listingGridWrapper',
              }}
              cardView={(assetId: string) => (
                <ListingCard
                  key={assetId}
                  id={assetId}
                  siblings={data?.result}
                  imageUrl={getThumbnailUrl({ width: 300, height: 300, id: assetId, jwt: hooks.accessToken })}
                  name={data?.extended_result[assetId]?.name}
                  size={data?.extended_result[assetId]?.size}
                  contentType={data?.extended_result[assetId]?.content_type}
                  trashed={data?.extended_result[assetId]?.trashed}
                  status={data?.extended_result[assetId]?.status}
                  onSelectChange={() => handleOnAssetSelectChange(assetId)}
                  isSelected={hooks.state.selectedAssets.includes(assetId)}
                  error={data?.extended_result[assetId]?.errors}
                />
              )}
              listView={(assetId: string) => (
                <ListingRow
                  key={assetId}
                  id={assetId}
                  siblings={data?.result}
                  imageUrl={getThumbnailUrl({ width: 100, height: 100, id: assetId, jwt: hooks.accessToken })}
                  name={data?.extended_result[assetId]?.name}
                  size={data?.extended_result[assetId]?.size}
                  uploadTime={data?.extended_result[assetId]?.upload_time}
                  contentType={data?.extended_result[assetId]?.content_type}
                  trashed={data?.extended_result[assetId]?.trashed}
                  status={data?.extended_result[assetId]?.status}
                  onSelectChange={() => handleOnAssetSelectChange(assetId)}
                  isSelected={hooks.state.selectedAssets.includes(assetId)}
                  error={data?.extended_result[assetId]?.errors}
                />
              )}
            />

            {hooks.state.totalPages > 1 ? (
              <div className={styles.pagerWrapper}>
                <Pager
                  currentPage={hooks.reducer.filters.page}
                  totalPages={hooks.state.totalPages}
                  onChange={(page) => hooks.reducer.dispatchFilter({ page, hash: hooks.refs.searchHash.current })}
                  itemsPerPage={hooks.reducer.filters.limit_per_page}
                  onItemsPerPageChange={(newLimit) =>
                    hooks.reducer.dispatchFilter({ page: 1, hash: '', limit_per_page: newLimit })
                  }
                  datatest="pager-button-click"
                />
              </div>
            ) : (<div style={{ marginBottom: "20px" }}></div>)}
          </div>
        </section>
      </div>
    </div>
  );
};

const FoldableFilter: React.FC<{ title: string; dataTest?: string }> = ({ title, children, dataTest }) => {
  const [isRevealed, setIsRevealed] = useState(() => {
    return title === 'File Name' || title === 'Filters';
  });

  return (
    <div className={styles.foldableContainer} data-test={dataTest}>
      <h4 onClick={() => setIsRevealed(!isRevealed)}>
        {isRevealed ? <CaretDown /> : <CaretRight />} {title}
      </h4>
      {isRevealed && <div className={styles.filterChildren}>{children}</div>}
    </div>
  );
};
