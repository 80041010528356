import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import OpenSeaDragon from 'openseadragon';
import { useAuthUser } from 'react-auth-kit';
import { useQueryClient } from 'react-query';
import { useAssetDetailsQuery } from 'src/api/queries/assetsQueries';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { getAssetMediaUrl } from 'src/api/functions/assetsApi';
import { useCollectionQuery } from 'src/api/queries/collectionsQueries';
import { useCollectionMutation } from 'src/api/mutations/collectionMutations';
import useRoleManager from 'src/hooks/useRoleManager';
import Dropdown from 'src/components/Dropdown';
import {
  X,
  FileArrowDown,
  ArrowsClockwise,
  DropdownArrow,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  CaretLeft,
  CaretRight,
  PencilSimple,
  Panel,
  CaretDown,
} from 'src/common/icons';
import { Checkbox, ConfirmDialog, Loader } from 'src/components';
import { FlatButton } from './components/FlatButton';
import { ModalPreview } from './ModalPreview';
import { ModalSidebar } from './ModalSidebar';
import { config } from 'src/utils/config';
import { queryNames } from 'src/api/utils/queryNames';
import { CollectionResponse } from 'src/types/TCollection';
import messages from './messages';
import styles from './Modal.module.scss';
import { useAlerts } from 'src/common/AlertManager';
import at from 'src/utils/at';
import ModalEditor, { ConversionData, EDITION_MODE, OriginalData, SubtitleData } from './ModalEditor';
import RBGviewer from './RBGviewer';
import AssetModalContext from './context';
import Gear from 'src/common/icons/Gear';
import useFileSpinPicker from 'src/hooks/useFileSpinPicker';
import { setBodyScroll } from 'src/utils/bodyScroll';
import { addonsSettingsAtom } from 'src/shared/settings/addons';
import { getSchemas } from 'src/api/functions/schemaApi';

type ModalParams = {
  id: string;
};

const loadLoader = (callback: () => void) => {
  const existingScript = document.getElementById('filespinLoader');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = config.filespinLoader;
    script.id = 'filespinLoader';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

type EditingModeState = {
  type: EDITION_MODE;
  data: ConversionData | OriginalData | SubtitleData;
};

export const Modal: React.FC<{}> = () => {
  const history = useHistory<any>();
  const location = useLocation<any>();
  let { id } = useParams<ModalParams>();
  const assetDetailsQuery = useAssetDetailsQuery(id, undefined, {
    cacheTime: 0,
  });

  const { data, isLoading, refetch } = assetDetailsQuery;

  const [originalUrl, setOriginalUrl] = useState('');
  const [thumbnailUrl, getThumbnailUrl] = useState('');

  const [isZoomable, setIsZoomable] = useState(false);
  const [isZoomableActivated, setIsZoomableActivated] = useState(false);
  const [isEditModeActivated, setIsEditModeActivated] = useState(false);
  const [isRBGModeActivated, setIsRBGModeActivated] = useState(false);
  const [isCollectionMutated, setIsCollectionMutated] = useState(false);
  const [isGettingMediaUrl, setIsGettingMediaUrl] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(true);
  const [editingMode, setEditingMode] = useState<EditingModeState | null>(null);
  const [newEditingMode, setNewEditingMode] = useState<EditingModeState | null>(null);
  const [viewer, setViewer] = useState<any>(null);
  const sidebarRef = useRef<any>();
  const auth = useAuthUser();
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const { showSuccess } = useAlerts();
  const { isUser } = useRoleManager();
  const [addonsSettings] = useAtom(addonsSettingsAtom);
  const [schemas, setSchemas] = useState<any>([]);
  const { replaceContent } = useFileSpinPicker({
    events: {
      complete: () => {
        queryClient.invalidateQueries([queryNames.asset, id]);
      },
    },
  });

  const fetchSchemas = async () => {
    const result = await getSchemas(accessToken);
    if (!result.data) return;
    setSchemas(result.data);
  };
  const siblings = location?.state?.siblings;

  const { formatMessage } = useIntl();

  const hasBackground = !!location?.state?.background;

  useEffect(() => {
    if (!hasBackground) return;

    setBodyScroll('100vh', 'hidden');

    return () => {
      setBodyScroll('', '');
    };
  }, []);
  const { data: basket, isLoading: isLoadingBasket } = useCollectionQuery();

  const basketMutation = useCollectionMutation(Number(basket?.data?.id), {
    onError: (error, newData, context: any) => {
      // Rollback mechanism
      queryClient.setQueryData([queryNames.collection, null], context.previousData);
      // TODO: Handle error, show feedback
    },
    onSettled: (data) => {
      setIsCollectionMutated(false);
    },
    onMutate: (data) => {
      setIsCollectionMutated(true);
      const previousData = queryClient.getQueryData<CollectionResponse>([queryNames.collection, null]);
      queryClient.setQueryData<CollectionResponse | undefined>(
        [queryNames.collection, null],
        (old: CollectionResponse | undefined) => {
          if (typeof old !== 'undefined') {
            old.data.assets = (old?.data?.assets ?? [])
              .filter((id) => !(data?.deletions ?? []).includes(id))
              .concat(data?.additions ?? []);
          }
          return old;
        },
      );
      return { previousData };
    },
  });

  useEffect(() => {
    async function loadSrc() {
      const url = await getAssetMediaUrl(id, 'original', auth()!.accessId, 'display', accessToken);

      setOriginalUrl(url);
    }
    const contentType = data?.content_type?.split('/')[0];
    if (contentType === 'image' && !data?.errors?.original) {
      loadSrc();
    } else {
      console.debug(null);
    }
    fetchSchemas()
    setIsEditModeActivated(false);
  }, [id, data]);

  useEffect(() => {
    const zoomable = data?.conversions.hasOwnProperty('deepzoom');
    const isPublicZoomable = zoomable && data?.conversions?.deepzoom?.public;
    setIsZoomable(isPublicZoomable);

    if (data && isPublicZoomable) InitOpenseadragon();
    return () => {
      viewer && viewer?.destroy();
    };
  }, [data]);

  const back = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    const {
      location: { state },
    } = history;

    if (!state || !state?.background) {
      history.goBack();
    }

    history.push(state?.background.pathname);
  };

  const toggleSidebar = (forceState?: boolean) => {
    setShowSidePanel(!showSidePanel);
    sidebarRef?.current?.toggleSidebar(forceState);
  };

  const InitOpenseadragon = () => {
    viewer && viewer?.destroy?.();
    setViewer(
      OpenSeaDragon({
        id: 'deepzoomContainer',
        prefixUrl: `/imagezoomableicons/`,
        tileSources: [`${config.hostUrl}/api/v1/zoomable/${id}/deepzoom.xml`],
        showNavigator: false,
        animationTime: 0.5,
        blendTime: 0.1,
        constrainDuringPan: true,
        // maxZoomPixelRatio: 2,
        minZoomLevel: 1,
        visibilityRatio: 1,
        zoomPerScroll: 2,
        showHomeControl: true,
        showNavigationControl: true,
        showZoomControl: true,
        showFullPageControl: true,
        navigatorDisplayRegionColor: '#0633DE',
      }),
    );
  };

  const handleZoomOutClick = () => {
    if (viewer?.viewport?._oldZoom <= 1) {
      setIsZoomableActivated(false);
    } else {
      viewer?.viewport?.zoomBy(0.7);
    }
  };

  const handleZoomInClick = () => {
    if (isZoomableActivated) {
      viewer?.viewport?.zoomBy(1.3);
    } else {
      setIsZoomableActivated(true);
    }
  };

  const handleNextPreviousClick = (delta: 1 | -1) => {
    const currentIdx = siblings.findIndex((a: string) => a === id);
    history.replace({
      pathname: `/asset/${siblings[currentIdx + delta]}`,
      state: {
        background: location?.state?.background,
        siblings,
      },
    });
  };

  const handleDownload = async () => {
    setIsGettingMediaUrl(true);
    const url = await getAssetMediaUrl(id, 'original', auth()!.accessId, 'download', accessToken);
    window.open(url);
    setIsGettingMediaUrl(false);
  };
  const handleToolClick = (key: string) => {
    switch (key) {
      case 'Remove Background':
        handleRBG();
        break;
      default:
        break;
    }
  };
  const getAddonOptions = () => {
    const options = [];
    options.push({
      key: 'Remove Background',
      value: 'Remove Background',
      noBorderBottom: false,
    });
    // options.push({
    //   key: 'Additional',
    //   value: 'Additional',
    //   noBorderBottom: false,
    // });
    return options;
  };
  const handleFileLoader = async () => {
    replaceContent(id);
  };

  const handleBasketToggle = async (state: boolean) => {
    if (typeof basket?.data?.assets === 'undefined') return;

    setIsCollectionMutated(true);
    if (state === true) {
      await basketMutation.mutateAsync({ additions: [id] });
      showSuccess(formatMessage(messages.addedToBasket));
    } else {
      await basketMutation.mutateAsync({ deletions: [id] });
      showSuccess(formatMessage(messages.removedFromBasket));
    }
    setIsCollectionMutated(false);
  };

  const handleRBG = () => {
    setIsZoomableActivated(false);
    setIsRBGModeActivated(true);
    setIsEditModeActivated(false);
  };
  const handleRBGImageSaved = () => {
    setIsRBGModeActivated(false);
  };

  const handleAssetEdit = (type: EDITION_MODE, data: OriginalData | ConversionData | SubtitleData) => {
    if (isEditModeActivated) {
      setNewEditingMode({
        type,
        data,
      });
      setShowConfirmDialog(true);
      return;
    }

    setIsZoomableActivated(false);
    sidebarRef?.current?.toggleSidebar(false);
    setEditingMode({
      type,
      data,
    });
    setIsRBGModeActivated(false);
    setIsEditModeActivated(true);
  };

  const handleConfirmEditConversion = () => {
    setShowConfirmDialog(false);
    sidebarRef?.current?.toggleSidebar(false);
    setEditingMode(newEditingMode);
    setIsEditModeActivated(true);
  };

  const handleCancelEditing = () => {
    setIsEditModeActivated(false);
    setIsRBGModeActivated(false);
  };
  const handleOnFinishEditing = useCallback(() => {
    toggleSidebar(true);
    setIsEditModeActivated(false);
  }, []);

  const getApi = useMemo(() => ({ handleOnFinishEditing }), []);

  if (isLoading)
    return (
      <div onClick={back} className={styles.Modal}>
        <Loader className={styles.Loader} size="large" color="#fff" />
      </div>
    );

  const getAssetURL = (url: string) => {
    getThumbnailUrl(url);
  };
  return (
    <AssetModalContext.Provider value={getApi}>
      <div onClick={back} className={hasBackground ? styles.Modal : undefined}>
        <div className={hasBackground ? styles.wrapper : styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.header}>
            <div className={styles.actions}>
              {!isEditModeActivated && !isRBGModeActivated && (
                <>
                  <Checkbox
                    disabled={isCollectionMutated || data.errors?.original}
                    checked={basket?.data?.assets.includes(id)}
                    label={<FormattedMessage {...messages.add} />}
                    onChange={(v) => handleBasketToggle(v.target.checked)}
                  />
                  <div>
                    <FlatButton
                      disabled={isGettingMediaUrl}
                      lightIcon
                      onClick={handleDownload}
                      icon={<FileArrowDown />}
                    >
                      <FormattedMessage {...messages.downloadContent} />
                    </FlatButton>
                  </div>
                  <div>
                    {isUser ? (
                      <></>
                    ) : (
                      <FlatButton lightIcon onClick={handleFileLoader} icon={<ArrowsClockwise />}>
                        <FormattedMessage {...messages.replaceContent} />
                      </FlatButton>
                    )}
                  </div>
                  {data?.content_type.split('/')[0] === 'image' && !data.errors?.original && (
                    <div>
                      {isUser ? (
                        <></>
                      ) : (
                        <FlatButton
                          lightIcon
                          onClick={() =>
                            handleAssetEdit(EDITION_MODE.ORIGINAL, {
                              name: data?.name,
                              url: originalUrl,
                            })
                          }
                          icon={<PencilSimple />}
                          datatest="edit-button"
                        >
                          <FormattedMessage {...messages.editImage} />
                        </FlatButton>
                      )}
                    </div>
                  )}
                  {data?.content_type.split('/')[0] === 'image' &&
                    !data.errors?.original &&
                    addonsSettings?.REMOVE_BACKGROUND?.added &&
                    addonsSettings?.REMOVE_BACKGROUND?.enabled && (
                      <div>
                        <Dropdown
                          dropdownAnchor="left"
                          dropdownDirection="down"
                          icon={
                            <FlatButton lightIcon onClick={() => {}} icon={<DropdownArrow />}>
                              {<FormattedMessage {...messages.tools} />}
                            </FlatButton>
                          }
                          onClick={handleToolClick}
                          options={getAddonOptions()}
                          className={styles.dropdown}
                        />
                      </div>
                    )}
                  {isZoomable && data?.content_type.split('/')[0] === 'image' && !data.errors?.original && (
                    <div className={styles.push}>
                      <FlatButton onClick={handleZoomOutClick} icon={<MagnifyingGlassMinus />} />
                      <FlatButton onClick={handleZoomInClick} icon={<MagnifyingGlassPlus />} />
                    </div>
                  )}
                </>
              )}
              {isEditModeActivated && (
                <FlatButton lightIcon onClick={handleCancelEditing} icon={<PencilSimple />} datatest="close-edit">
                  <FormattedMessage {...messages.cancelEditImage} />
                </FlatButton>
              )}
              {isRBGModeActivated && (
                <FlatButton lightIcon onClick={handleCancelEditing} icon={<CaretLeft />}>
                  <FormattedMessage {...messages.back} />
                </FlatButton>
              )}
            </div>
            <div className={styles.controls}>
              <div className={styles.title}>
                {data.errors?.original && <div className={styles.errortext}>ERROR</div>}
                {data.name}
              </div>

              <div className={styles.push}>
                {siblings && !isRBGModeActivated && (
                  <>
                    <FlatButton
                      disabled={id === siblings[0]}
                      onClick={() => handleNextPreviousClick(-1)}
                      icon={<CaretLeft />}
                    />
                    <FlatButton
                      disabled={id === at(siblings, -1)}
                      onClick={() => handleNextPreviousClick(1)}
                      icon={<CaretRight />}
                    />
                  </>
                )}
              </div>

              <div>
                <FlatButton onClick={() => toggleSidebar()} icon={<Panel />} />
              </div>
              {hasBackground && (
                <div>
                  <FlatButton onClick={back} icon={<X />} datatest="close-asset-modal" />
                </div>
              )}
            </div>
          </div>
          <>
            <div className={styles.content}>
              <div className={styles.preview}>
                {data.status === 'NOT_READY' && (
                  <div className={styles.processingAsset}>
                    <Gear /> <span>Processing</span>
                  </div>
                )}
                <div
                  id="deepzoomContainer"
                  style={{ width: '100%', height: '100%', display: isZoomableActivated ? 'block' : 'none' }}
                ></div>
                {!isEditModeActivated && !isZoomableActivated && !isRBGModeActivated && (
                  <ModalPreview
                    assetType={data.content_type}
                    assetId={id}
                    conversions={data.conversions}
                    dimensions={[data.metadata?.width, data.metadata?.height]}
                    getURL={getAssetURL}
                  />
                )}
                {isEditModeActivated && editingMode && (
                  <ModalEditor assetId={id} type={editingMode.type} data={editingMode.data} metadata={data} />
                )}
                {isRBGModeActivated && (
                  <RBGviewer
                    assetId={id}
                    assetData={data}
                    onRBGImageSaved={handleRBGImageSaved}
                    name={data.name}
                    url={thumbnailUrl}
                  />
                )}
              </div>
              <ModalSidebar asset={data} schemas={schemas} ref={sidebarRef} onEditConversion={handleAssetEdit}  />
            </div>
          </>
        </div>
      </div>
      {showConfirmDialog && (
        <ConfirmDialog
          title={formatMessage(messages.editConfirmDialogTitle)}
          message={formatMessage(messages.editConfirmDialogMessage)}
          onConfirm={handleConfirmEditConversion}
          onCancel={() => setShowConfirmDialog(false)}
        />
      )}
    </AssetModalContext.Provider>
  );
};
