export const humanFileSize = (bytes: number) => {
  let u,
    b = bytes,
    t = 1000;
  // eslint-disable-next-line
  ['', 'K', ...'MGTPEZY'].find((x) => ((u = x), (b /= t), b ** 2 < 1));
  return `${u ? (t * b).toFixed(1) : bytes} ${u}B`;
};

const timeScalars = [1000, 60, 60, 24];
const timeUnits = ['ms', 's', 'm', 'h'];

export const humanDuration = (miliseconds: number) => {
  let timeScalarIndex = 0,
    scaledTime = miliseconds;

  while (scaledTime > timeScalars[timeScalarIndex]) {
    scaledTime /= timeScalars[timeScalarIndex++];
  }

  return `${scaledTime.toFixed(0)} ${timeUnits[timeScalarIndex]}`;
};

export const formatSeconds = (seconds: number): string => {
  if (seconds < 60) {
    return `${seconds}s`;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    if (minutes === 0 && remainingSeconds === 0) {
      return `${hours}h`;
    } else if (remainingSeconds === 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  } else if (minutes > 0) {
    if (remainingSeconds === 0) {
      return `${minutes}m`;
    }
    return `${minutes}m ${remainingSeconds}s`;
  }

  return `${remainingSeconds}s`;
};