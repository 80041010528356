import * as React from 'react';

function Folder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
        <path d="M3.00024 19.5V6C3.00024 5.80109 3.07926 5.61032 3.21991 5.46967C3.36057 5.32902 3.55133 5.25 3.75024 5.25H8.75024C8.91252 5.25 9.07042 5.30263 9.20024 5.4L11.8002 7.35C11.9301 7.44737 12.088 7.5 12.2502 7.5H18.7502C18.9492 7.5 19.1399 7.57902 19.2806 7.71967C19.4212 7.86032 19.5002 8.05109 19.5002 8.25V10.5" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.00024 19.5L5.8293 11.0128C5.87908 10.8635 5.97458 10.7336 6.10228 10.6416C6.22998 10.5495 6.3834 10.5 6.54081 10.5H21.4597C21.5785 10.5 21.6957 10.5282 21.8014 10.5824C21.9072 10.6365 21.9986 10.7151 22.0681 10.8115C22.1376 10.9079 22.1832 11.0194 22.2011 11.1369C22.219 11.2544 22.2088 11.3744 22.1712 11.4872L19.5002 19.5H3.00024Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default Folder;
