import * as React from 'react';

function Camera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
        <path d="M19.5001 19.5H4.50012C4.1023 19.5 3.72077 19.342 3.43946 19.0607C3.15816 18.7794 3.00012 18.3978 3.00012 18V7.5C3.00012 7.10218 3.15816 6.72064 3.43946 6.43934C3.72077 6.15804 4.1023 6 4.50012 6H7.49957L8.99957 3.75H14.9996L16.4996 6H19.5001C19.8979 6 20.2795 6.15804 20.5608 6.43934C20.8421 6.72064 21.0001 7.10218 21.0001 7.5V18C21.0001 18.3978 20.8421 18.7794 20.5608 19.0607C20.2795 19.342 19.8979 19.5 19.5001 19.5Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0001 15.75C13.8641 15.75 15.3751 14.239 15.3751 12.375C15.3751 10.511 13.8641 9 12.0001 9C10.1362 9 8.62512 10.511 8.62512 12.375C8.62512 14.239 10.1362 15.75 12.0001 15.75Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default Camera;
