import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowsClockwise, MagnifyingGlass, SlidersHorizontal } from 'src/common/icons';
import { Button, Checkbox, Input, Pager, PageTitle, Table } from 'src/components';
import messages from './messages';

import KeysModal from './components/KeysModal';

import styles from './styles.module.scss';
import { useEffect, useReducer, useState } from 'react';
import AddUserModal from './components/AddUserModal';
import { useUserManagementQuery } from 'src/api/queries/userManagementQueries';
import LoadingScreen from './components/LoadingScreen';
import SvgKey from 'src/common/icons/Key';
import SvgWrench from 'src/common/icons/Wrench';
import EditUserModal from './components/EditUserModal';
import { motion } from 'framer-motion';
import { useDebounce } from 'use-debounce';

import { isEmpty } from 'lodash';
import useModal from 'src/hooks/useModal';
import { useAuthUser } from 'react-auth-kit';

const initialFilters = {
  page: 1,
  limit_per_page: 30,
  email: '',
  first_name: '',
  last_name: '',
  enabled: false,
};

const filtersReducer = (state: any, payload: { [key: string]: any }) => ({ ...state, ...payload });

const Users = () => {
  const auth = useAuthUser();
  const authenticatedUser = auth();
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);

  const [emailSearch, setEmailSearch] = useState('');
  const [firstNameSearch, setFirstNameSearch] = useState('');
  const [lastNameSearch, setLastNameSearch] = useState('');
  const [enabledOnly, setEnabledOnly] = useState(false);

  const [filters, dispatchFilter] = useReducer(filtersReducer, initialFilters);

  const [emailSearchDebounced] = useDebounce(emailSearch, 500);
  const [firstNameSearchDebounced] = useDebounce(firstNameSearch, 500);
  const [lastNameSearchDebounced] = useDebounce(lastNameSearch, 500);
  const [enabledOnlyDebounced] = useDebounce(enabledOnly, 500);

  const { showModal, closeModal } = useModal();

  const [totalPage, setTotalPage] = useState(1);

  const { formatMessage } = useIntl();

  const { data, isLoading, refetch, isRefetching } = useUserManagementQuery(filters, {
    onSuccess: (results) => {
      setTotalPage(Math.ceil(results.total_users / filters.limit_per_page));
    },
  });




  const handleShowKeysModal = (user: any) => showModal(<KeysModal user={user} />);

  const handleShowAddUserModal = () => showModal(<AddUserModal onAddUser={refetch} />);

  const handleShowEditUserModal = (user: any) => showModal(<EditUserModal user={user} onEditUser={refetch} />);

  const handleToggleFilter = () => {
    setShowFilterSidebar((state) => !state);
  };

  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    dispatchFilter({ email: emailSearchDebounced, page: 1 });
  }, [emailSearchDebounced]);

  useEffect(() => {
    dispatchFilter({ first_name: firstNameSearchDebounced, page: 1 });
  }, [firstNameSearchDebounced]);

  useEffect(() => {
    dispatchFilter({ last_name: lastNameSearchDebounced, page: 1 });
  }, [lastNameSearchDebounced]);

  useEffect(() => {
    dispatchFilter({ enabled: enabledOnlyDebounced, page: 1 });
  }, [enabledOnlyDebounced]);

  return (
    <div data-test="user-page-content">
      <PageTitle
        className={styles.pageWrapper}
        title={formatMessage(messages.pageTitle)}
        counter={data?.total_users}
        rightContainer={
          <Button size="small" onClick={handleShowAddUserModal}>
            <FormattedMessage {...messages.addUser} />
          </Button>
        }
      />
      <div className={styles.searchSection}>
        <div>
          <Button icon={<SlidersHorizontal />} mode="flat" onClick={handleToggleFilter} />
          <Input
            placeholder={formatMessage(messages.searchPlaceholder)}
            value={emailSearch}
            onChange={setEmailSearch}
            icon={<MagnifyingGlass />}
          />
        </div>

        <Button icon={<ArrowsClockwise />} mode="flat" onClick={handleRefetch} loading={isRefetching} />
      </div>
      <div className={styles.pageContent}>
        <motion.div
          initial={false}
          animate={{
            x: showFilterSidebar ? '0%' : '-100%',
          }}
          className={styles.sidebar}
          transition={{ ease: 'easeInOut', duration: 0.2 }}
        >
          <h4>
            <FormattedMessage {...messages.searchFilter} />
          </h4>

          <Input label={formatMessage(messages.firstName)} value={firstNameSearch} onChange={setFirstNameSearch} />
          <Input
            className={styles.formSpacing}
            label={formatMessage(messages.lastName)}
            value={lastNameSearch}
            onChange={setLastNameSearch}
          />
          <Checkbox
            label={formatMessage(messages.enabledOnly)}
            checked={enabledOnly}
            onChangeCapture={() => setEnabledOnly((state) => !state)}
          />
        </motion.div>
        <div className={styles.container}>
          {isLoading ? (
            <LoadingScreen message={formatMessage(messages.loadingMessage)} />
          ) : (
            <>
              <Table
                className={styles.centeredTable}
                headers={[
                  'ID',
                  'Email',
                  formatMessage(messages.firstName),
                  formatMessage(messages.lastName),
                  formatMessage(messages.role),
                  formatMessage(messages.keys),
                  formatMessage(messages.status),
                  formatMessage(messages.options),
                ]}
              >
                {data.users.map((user: any) => {
                  const { id, email, first_name, last_name, role_name, enabled } = user;

                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{email}</td>
                      <td>{first_name}</td>
                      <td>{last_name}</td>
                      <td>{role_name}</td>
                      <td>
                        <Button icon={<SvgKey />} mode="primary" size="small" onClick={() => handleShowKeysModal(user)}>
                          <FormattedMessage {...messages.clickToView} />
                        </Button>
                      </td>
                      <td>{formatMessage(enabled ? messages.enabled : messages.disabled)}</td>
                      <td>
                        <Button
                          icon={<SvgWrench />}
                          mode="flat"
                          size="small"
                          onClick={() => handleShowEditUserModal(user)}
                          disabled={id === authenticatedUser?.userId}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Table>
              {data?.total_users > 30 && (
                <div className={styles.pagerWrapper}>
                  <Pager
                    currentPage={filters.page}
                    totalPages={totalPage}
                    onChange={(page) => dispatchFilter({ page })}
                    itemsPerPage={30}
                    onItemsPerPageChange={(newLimit) => {
                      return newLimit;
                    }}
                    showItemsPerPage={false}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/*modalKeysUser && <KeysModal user={modalKeysUser} visible={showKeysModal} onClose={handleCloseKeyModal} />}
      <AddUserModal visible={showAddUserModal} onClose={handleCloseAddUserModal} />
      {selectedUser && (
        <EditUserModal
          user={selectedUser}
          visible={showEditUserModal}
          onClose={handleCloseEditUserModal}
          onEditUser={handleEditUser}
        />
      )*/}
    </div>
  );
};

export default Users;
