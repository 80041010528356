import React, { useState } from 'react';
import BaseModal from '../BaseModal';
import styles from './BatchCdn.module.scss';
import Input from '../Input';
import Select from '../Select';
import Button from '../Button';
import { useAlerts } from 'src/common/AlertManager';
import { batchCDNPrefetch, batchCDNPurge } from 'src/api/functions/assetsApi';
import { useAccessToken } from 'src/hooks/useAccessToken';
import useModel from 'src/hooks/useModal';

interface Props {}

export const BatchCdnModal: React.FC<Props> = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { showAlert, showSuccess } = useAlerts();
  const accessToken = useAccessToken();
  const { closeModal } = useModel();

  const [dataToSend, setDataToSend] = useState<{
    urls: string[];
    cache_expiry: number;
    action: string;
  }>({
    urls: [],
    cache_expiry: 12,
    action: 'PREFETCH',
  });

  const modifySetIds = (data: string[]) => {
    setDataToSend((prev: any) => {
      const d = { ...prev };
      d['urls'] = data;
      return d;
    });
  };

  const splitId = (stringID: string) => {
    if (stringID.length !== 0) {
      let urls: string[] = stringID.split(',');
      if (urls.length > 1000) {
        setErrorMessage("Number of Asset URL's must be lesser than 1000");
        return;
      }
      modifySetIds(urls);
    } else {
      setErrorMessage('');
      modifySetIds([]);
    }
  };

  const submitBtn = () => {
    setLoading(true);
    const urls = dataToSend['urls'];
    const cache_expiry = dataToSend['cache_expiry'];
    if (cache_expiry > 720) {
      setErrorMessage('Max cache expiry cannot exceed 720 hours');
      setLoading(false);
      return;
    } else if (cache_expiry < 12) {
      setErrorMessage('Min cache expiry is 12 hours');
      setLoading(false);
      return;
    } else {
      setErrorMessage('');
    }
    if (dataToSend['action'] === 'PREFETCH') {
      batchCDNPrefetch({ asset_urls: urls, cache_expiry: cache_expiry }, accessToken)
        .then((res) => {
          closeModal();
          showSuccess('Prefetched Asset Successfully !');
        })
        .catch((err) => {
          showAlert('Error Initiating Prefetch !');
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (dataToSend['action'] === 'PURGE') {
      batchCDNPurge({ asset_urls: urls }, accessToken)
        .then((res) => {
          closeModal();
          showSuccess('Purge Asset Initiated !');
        })
        .catch((err) => {
          showAlert('Error Initiating Purge !');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <BaseModal containerStyle={{ width: '90%', height: '90%', overflow: 'scroll' }}>
        <h3 className={styles.modal_header} data-test="modal">CDN Prefetch/Purge</h3>
        <div style={{ margin: '10px', height: '65%' }}>
          <textarea
            id="assetIds"
            className={styles.textarea_sty}
            placeholder="Enter Asset URL's (comma separated, no spaces). Asset URLs should begin with /video or /conversion like  /video/0342c71650224fa7b5ef234acab7bee8/1080p-wm-video.mp4 or /conversion/0242c71650224fa7b5ef234acab7bee8"
            onChange={(e) => {
              splitId(e.target.value);
            }}
            value={dataToSend['urls']}
          />
        </div>

        <span className={styles.error_sty} style={{ visibility: errorMessage ? 'visible' : 'hidden' }}>
          {errorMessage}
        </span>

        <div style={{ display: 'flex', gap: 20 }}>
          <div className={styles.option_holder}>
            <h4 className={styles.option_header}>Cache Expiry</h4>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Input
                className={styles.content_input}
                type="number"
                min={12}
                max={720}
                placeholder=""
                value={dataToSend.cache_expiry}
                onChange={(v) => {
                  setErrorMessage('');
                  setDataToSend((prev: any) => {
                    return { ...prev, cache_expiry: v };
                  });
                }}
              />
              <span>hours</span>
            </div>
          </div>

          <div className={styles.option_holder}>
            <h4 className={styles.option_header}>Action</h4>
            <Select
              className={styles.Select}
              options={[
                { label: 'PreFetch', value: 'PREFETCH' },
                { label: 'Purge', value: 'PURGE' },
              ]}
              value={dataToSend.action}
              onChange={(v) => {
                setErrorMessage('');
                setDataToSend((prev: any) => {
                  return { ...prev, action: v };
                });
              }}
            />
          </div>

          <div className={styles.option_holder} style={{ alignSelf: 'end' }}>
            <Button
              className={styles.btnStyle}
              size="small"
              disabled={errorMessage?.length !== 0 || dataToSend['urls']?.length === 0}
              onClick={submitBtn}
              loading={loading}
            >
              OK
            </Button>
          </div>
        </div>
      </BaseModal>
    </React.Fragment>
  );
};
