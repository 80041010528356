import React from 'react';

import styles from '../Modal.module.scss';

type TFlatButton = {
  icon: React.ReactNode;
  onClick: (params?: any) => void;
  disabled?: boolean;
  lightIcon?: boolean;
  datatest?:string
};

export const FlatButton: React.FC<TFlatButton> = ({ icon, lightIcon, disabled, onClick, children ,datatest}) => (
  <button type="button" className={styles.FlatButton} onClick={onClick} disabled={disabled} data-test={datatest}>
    <span className={lightIcon ? styles.alticon : ''}>{icon}</span>
    {children && <span>{children}</span>}
  </button>
);
