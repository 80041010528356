import * as React from 'react';

function SvgRadioButtonChecked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
     
      <circle cx={12} cy={12} r={12} fill="#E7EBFC" />
      
 
      <circle cx={12} cy={12} r={5} fill="currentColor" />
    </svg>
  );
}

export default SvgRadioButtonChecked;
